import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./FoodsAndBeveregesManagement.css";
import CustomToggleButton from "../../../../common/button/CustomToggleButton";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const FoodsAndBeveregesManagement = () => {
  const initialValues = {
    itemDescription: "",
    price: "",
    poster: "",
  };
  const { adminLoginData } = PagesIndex.useSelector(
    (state) => state.admin.AdminSlice
  );
  const [filteredData, setFilteredData] = useState([]);
  const [foodsAndBeveregesList, setFoodsAndBeveragesList] = useState([]);
  const [cinemaList, setCinemaList] = useState([]);
  const [selectedCinema, setSelectedCinema] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [addOrEdit, setAddOrEdit] = useState("Add");
  const [id, setId] = useState("");

  // State for pagination
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    getCinemaList();
  }, []);
  useEffect(() => {
    if (selectedCinema) {
      getFoodsAndBeveragesList();
    }
  }, [selectedCinema]);

  const handleOpen = (type) => {
    setOpen(true);
    setAddOrEdit(type);
  };
  const handleClose = () => {
    setId("");
    setOpen(false);
    setImageUrl("");
  };
  const handleDeleteOpen = (id) => {
    setId(id);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setId("");
  };
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage - 1);
  };

  const getCinemaList = () => {
    PagesIndex.DataService.get(PagesIndex.Api.GET_CINEMA)
      .then((res) => {
        setCinemaList(res?.data?.data);
        setSelectedCinema(res?.data?.data[0]?._id);
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };
  const getFoodsAndBeveragesList = () => {
    PagesIndex.DataService.get(
      PagesIndex.Api.GET_FOODS_AND_BAVERAGES + "/" + selectedCinema
    )
      .then((res) => {
        setFoodsAndBeveragesList(res?.data?.data);
        setFilteredData(res?.data?.data);
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };
  const handleStatus = (event, id) => {
    const data = {
      id: id,
      isActive: event.target.checked,
    };
    PagesIndex.DataService.post(PagesIndex.Api.ACTIVE_DEACTIVE_FOOD_ITEM, data)
      .then((res) => {
        if (res?.data?.status === 200) {
          PagesIndex.toast.success(res.data.message);
          getFoodsAndBeveragesList();
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };
  const handleItemSubmit = (values) => {
    const urlEncoded = new FormData();
    if (id) {
      urlEncoded.append("id", id);
    }
    if (typeof values?.poster === "object") {
      urlEncoded.append("poster", values?.poster);
    } else {
      urlEncoded.append("poster", "");
    }
    PagesIndex.DataService.post(PagesIndex.Api.EDIT_FOOD_ITEM, urlEncoded)
      .then((res) => {
        PagesIndex.toast.success(res.data.message);
        handleClose();
        getFoodsAndBeveragesList();
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };
  // Search on table
  const requestSearch = (searched) => {
    setCurrentPage(0);
    let filteredData = foodsAndBeveregesList.filter((data) =>
      data?.itemDescription?.toLowerCase()?.includes(searched?.toLowerCase())
    );
    setFilteredData(filteredData);
  };
  return (
    <PagesIndex.Formik
      enableReinitialize
      onSubmit={handleItemSubmit}
      initialValues={initialValues}
      validationSchema={PagesIndex.fAndBSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldTouched,
        setFieldValue,
      }) => (
        <>
          <Index.Box className="">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      Foods and Beverages
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="d-flex align-items-center res-set-search">
                    <Index.Box className="input-box modal-input-box">
                      <Index.FormHelperText className="form-lable">
                        Cinema
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.Select
                          fullWidth
                          id="fullWidth"
                          name="category"
                          className="form-control"
                          value={selectedCinema}
                          onChange={(e) => {
                            setSelectedCinema(e.target.value);
                          }}
                        >
                          {cinemaList.map((data) => {
                            return (
                              <Index.MenuItem value={data?._id}>
                                {data?.displayName}
                              </Index.MenuItem>
                            );
                          })}
                        </Index.Select>
                      </Index.Box>
                    </Index.Box>
                    <Search className="search ">
                      <StyledInputBase
                        placeholder="Search"
                        inputProps={{ "aria-label": "search" }}
                        onChange={(e) => requestSearch(e.target.value)}
                      />
                    </Search>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  <Index.Table
                    aria-label="simple table"
                    className="table-design-main one-line-table region-manage-table"
                  >
                    <Index.TableHead>
                      <Index.TableRow>
                        <Index.TableCell>Image</Index.TableCell>
                        <Index.TableCell>Item Name</Index.TableCell>
                        <Index.TableCell>Price</Index.TableCell>
                        {adminLoginData?.roleId?.permissions?.includes(
                          "food_beverages_edit"
                        ) && <Index.TableCell>Status</Index.TableCell>}
                        {adminLoginData?.roleId?.permissions?.includes(
                          "food_beverages_edit"
                        ) && (
                          <Index.TableCell align="right">
                            Action
                          </Index.TableCell>
                        )}
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody>
                      {filteredData?.length ? (
                        filteredData
                          ?.slice(currentPage * 10, currentPage * 10 + 10)
                          ?.map((item, index) => (
                            <Index.TableRow
                              // className="inquiry-list"
                              key={item?._id}
                            >
                              <Index.TableCell>
                                <Index.Box className="class_img">
                                  <img
                                    src={
                                      item?.poster
                                        ? `${PagesIndex.IMAGES_API_ENDPOINT}/${item?.poster}`
                                        : PagesIndex.Png.NoImageAvailable
                                    }
                                    onClick={handleClose}
                                    alt=""
                                  />
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell>
                                {item?.itemDescription
                                  ? item?.itemDescription
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell>
                                {item.itemPrice
                                  ? parseFloat(item.itemPrice / 100).toFixed(2)
                                  : "-"}
                              </Index.TableCell>
                              {adminLoginData?.roleId?.permissions?.includes(
                                "food_beverages_edit"
                              ) && (
                                <Index.TableCell align="center">
                                  <CustomToggleButton
                                    defaultChecked={item?.isActive}
                                    onChange={(e) => handleStatus(e, item?._id)}
                                  />
                                </Index.TableCell>
                              )}
                              {adminLoginData?.roleId?.permissions?.includes(
                                "food_beverages_edit"
                              ) && (
                                <Index.TableCell align="right">
                                  <Index.IconButton
                                    onClick={(e) => {
                                      setId(item?._id);
                                      handleOpen("Edit");
                                      if (item?.poster) {
                                        setImageUrl(
                                          `${PagesIndex?.IMAGES_API_ENDPOINT}/${item?.poster}`
                                        );
                                      }
                                      setFieldValue(
                                        "itemDescription",
                                        item?.itemDescription
                                      );
                                      setFieldValue(
                                        "price",
                                        parseFloat(
                                          item.itemPrice / 100
                                        ).toFixed(2)
                                      );
                                      setFieldValue("poster", item.poster);
                                    }}
                                  >
                                    <Index.EditIcon />
                                  </Index.IconButton>
                                  {/* <Index.IconButton
                                  onClick={() => handleDeleteOpen(item?._id)}
                                >
                                  <Index.DeleteIcon />
                                </Index.IconButton> */}
                                </Index.TableCell>
                              )}
                            </Index.TableRow>
                          ))
                      ) : (
                        <Index.TableRow>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="no-data-in-list"
                            colSpan={15}
                            align="center"
                          >
                            No data available
                          </Index.TableCell>
                        </Index.TableRow>
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="pagination-design flex-end">
                <Index.Stack spacing={2}>
                  <Index.Box className="pagination-count">
                    <Index.Pagination
                      count={Math.ceil(filteredData.length / 10)}
                      page={currentPage + 1}
                      onChange={handleChangePage}
                    />
                  </Index.Box>
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal"
          >
            <Index.Box
              sx={style}
              className="modal-inner-main add-region-modal modal-inner"
            >
              <Index.Box className="modal-header">
                <Index.Typography
                  id="modal-modal-title"
                  className="modal-title"
                  variant="h6"
                  component="h2"
                >
                  {addOrEdit} Item
                </Index.Typography>
                <img
                  src={PagesIndex.Svg.cancel}
                  className="modal-close-icon"
                  onClick={handleClose}
                />
              </Index.Box>

              <Index.Box className="modal-body">
                <Index.Stack
                  component="form"
                  spacing={2}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Item name
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        name="title"
                        className="form-control"
                        placeholder="Add item name"
                        disabled
                        value={values?.itemDescription}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        error={errors.title && touched.title ? true : false}
                        helperText={
                          errors.title && touched.title ? errors.title : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Price
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        name="title"
                        className="form-control"
                        placeholder="Add item name"
                        disabled
                        value={values?.price}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        error={errors.title && touched.title ? true : false}
                        helperText={
                          errors.title && touched.title ? errors.title : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Image (size 214x173 px)
                    </Index.FormHelperText>
                    {/* <Index.Box
                      className={`form-group region_img_upload ${errors.poster && touched.poster ? "error" : ""
                        }`}
                    >
                      <Index.Box className="common-button grey-button change-profile">
                        <Index.InputLabel>
                          <input
                            type="file"
                            className="change-profile-input"
                            accept="image/*"
                            name="poster"
                            onChange={(e) => {
                              try {
                                setFieldValue(
                                  "poster",
                                  e.currentTarget.files[0]
                                );
                                setImageUrl(
                                  URL.createObjectURL(e.currentTarget.files[0])
                                );
                              } catch (error) {
                                console.error(error);
                                e.currentTarget.value = null;
                              }
                            }}
                            error={
                              errors.poster && touched.poster ? true : false
                            }
                            helperText={
                              errors.poster && touched.poster
                                ? errors.poster
                                : false
                            }
                          />
                        </Index.InputLabel>
                      </Index.Box>
                    </Index.Box> */}
                    <Index.Box className="file-upload-btn-main">
                      <Index.Button
                        variant="contained"
                        component="label"
                        className="file-upload-btn"
                      >
                        {imageUrl ? (
                          <img src={imageUrl} className="upload-profile-img" />
                        ) : (
                          <img
                            className="
                            upload-img"
                            src={PagesIndex.Svg.add}
                          />
                        )}
                        <input
                          hidden
                          accept="image/*"
                          multiple
                          name="poster"
                          type="file"
                          onChange={(e) => {
                            try {
                              setFieldValue("poster", e.currentTarget.files[0]);
                              setImageUrl(
                                URL.createObjectURL(e.currentTarget.files[0])
                              );
                            } catch (error) {
                              console.error(error);
                              e.currentTarget.value = null;
                            }
                          }}
                          error={errors.image && touched.image ? true : false}
                          helperText={
                            errors.image && touched.image ? errors.image : false
                          }
                        />
                      </Index.Button>
                    </Index.Box>
                    <Index.FormHelperText error>
                      {errors.poster && touched.poster ? errors.poster : false}
                    </Index.FormHelperText>
                  </Index.Box>
                  {/* {imageUrl && <img src={imageUrl} className="slider-image" />} */}
                  <Index.Box className="modal-user-btn-flex">
                    <Index.Box className="discard-btn-main btn-main-primary">
                      <Index.Box className="common-button blue-button res-blue-button">
                        <Index.Button
                          variant="contained"
                          disableRipple
                          className="no-text-decoration"
                          onClick={handleClose}
                        >
                          Discard
                        </Index.Button>
                        <Index.Button
                          type="submit"
                          variant="contained"
                          disableRipple
                          className="no-text-decoration"
                        >
                          <img
                            src={PagesIndex.Svg.save}
                            className="user-save-icon"
                          ></img>

                          {addOrEdit == "Add" ? "Save" : "Update"}
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Modal>
          <PagesIndex.DeleteModal
            deleteOpen={deleteOpen}
            handleDeleteClose={handleDeleteClose}
            // handleDeleteRecord={handleSliderRemove}
          />
        </>
      )}
    </PagesIndex.Formik>
  );
};

export default FoodsAndBeveregesManagement;
