import React, { useEffect, useRef, useState } from "react";
// import Index from "../../Index";
import { Link } from "react-router-dom";
import Index from "../../../container/Index";
import PagesIndex from "../../../container/PagesIndex";
import { adminLogout } from "../../../redux-toolkit/slice/admin-slice/AdminSlice";
import { adminProfile } from "../../../redux-toolkit/slice/admin-slice/AdminServices";

// import { useDispatch } from "react-redux";
// import { adminLogout } from "../../../redux/admin/action";
// const preventDefault = (event) => event.preventDefault();
function Header({
  setOpen,
  open,
  anchorEl,
  handleClose2,
  open2,
  handleClick2,
}) {
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  //   document.body.classList["add"]("menu-set-main");
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  //   document.body.classList["remove"]("menu-set-main");
  // };

  // const [openSidebar, setOpenSidebar] = useState(true);

  // const handleSidebarToogle = () => {
  //   setOpenSidebar(!openSidebar);
  // };
  // const dispatch = useDispatch();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };
  const initialValues = {
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    address: "",
    contact1: "",
    contact2: "",
    email: "",
    exp: "",
    noOfScreens: "",
  };
  const formikRef = useRef();
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  useEffect(() => {
    dispatch(adminProfile()).then((res) => {
      if (res?.payload?.message === "jwt expired") {
        handlelogout();
      }
    });
  }, []);
  const handlelogout = () => {
    dispatch(adminLogout());
    localStorage.removeItem("token");
    navigate("/");
  };
  const handleOpenSettingsModal = () => {
    getGeneralSettings();
    setOpenSettingsModal(true);
  };
  const handleCloseSettingsModal = () => {
    setOpenSettingsModal(false);
  };
  const getGeneralSettings = () => {
    PagesIndex.DataService.get(PagesIndex.Api.GET_GENERAL_SETTINGS)
      .then((res) => {
        formikRef.current.setFieldValue("facebook", res.data.data.facebookUrl);
        formikRef.current.setFieldValue("twitter", res.data.data.twitterUrl);
        formikRef.current.setFieldValue(
          "instagram",
          res.data.data.instagramUrl
        );
        formikRef.current.setFieldValue("youtube", res.data.data.youtubeUrl);
        formikRef.current.setFieldValue("address", res.data.data.address1);
        formikRef.current.setFieldValue(
          "contact1",
          res.data.data.contactNumber1
        );
        formikRef.current.setFieldValue(
          "contact2",
          res.data.data.contactNumber2
        );
        formikRef.current.setFieldValue("email", res.data.data.email);
        formikRef.current.setFieldValue("exp", res.data.data.yearOfExperience);
        formikRef.current.setFieldValue(
          "noOfScreens",
          res.data.data.noOfTheaterScreen
        );
      })
      .catch((err) => {
        PagesIndex.toast.error(err?.response?.data.message);
      });
  };

  const handleSettingsSubmit = (values) => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("facebookUrl", values?.facebook);
    urlEncoded.append("twitterUrl", values?.twitter);
    urlEncoded.append("instagramUrl", values?.instagram);
    urlEncoded.append("youtubeUrl", values?.youtube);
    urlEncoded.append("address1", values?.address);
    urlEncoded.append("contactNumber1", values?.contact1);
    urlEncoded.append("contactNumber2", values?.contact2);
    urlEncoded.append("email", values?.email);
    urlEncoded.append("yearOfExperience", values?.exp);
    urlEncoded.append("noOfTheaterScreen", values?.noOfScreens);
    PagesIndex.DataService.post(PagesIndex.Api.ADD_GENERAL_SETTINGS, urlEncoded)
      .then((res) => {
        PagesIndex.toast.success(res.data.message);
        handleCloseSettingsModal();
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };

  const { adminLoginData } = PagesIndex.useSelector(
    (state) => state.admin.AdminSlice
  );
  return (
    <div>
      <header>
        <Index.Box className={`main-header ${!open ? "" : "side-bar"}`}>
          <Index.Box
            className="collapse-btn"
            onClick={() => setOpen((e) => !e)}
          >
            <img src={PagesIndex?.Svg?.collapse} />
          </Index.Box>
          <Index.Box className="head-right">
            <img src={PagesIndex.Svg.search} className="search-icon" />
            <img src={PagesIndex.Svg.bell} className="bell-icon" />
            <img
              src={PagesIndex.Svg.settings}
              className="search-icon"
              onClick={handleOpenSettingsModal}
            />
          </Index.Box>

          <Index.Box className="admin-header-right">
            <Index.Box className="admin-header-drop-main">
              <Index.Button
                className="drop-header-btn"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick2}
              >
                <Index.Box className="head-right">
                  <Index.Box className="header-user-detail">
                    <Index.Typography variant="p" className="head-user-title">
                      {adminLoginData?.name}
                    </Index.Typography>
                    <Index.Typography variant="p" className="head-user-mail">
                      {adminLoginData?.email}
                    </Index.Typography>
                  </Index.Box>
                  <img
                    src={
                      adminLoginData?.image
                        ? `${PagesIndex.IMAGES_API_ENDPOINT}/${adminLoginData?.image}`
                        : PagesIndex?.Png?.user
                    }
                    className="headprofile"
                    alt=""
                  />
                </Index.Box>
              </Index.Button>
            </Index.Box>
            <Index.Menu
              className="drop-header-menu admin-header-profile-ul"
              id="basic-menu"
              anchorEl={anchorEl}
              open={open2}
              onClose={handleClose2}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Link to="/admin/profile">
                <Index.MenuItem
                  onClick={() => {
                    handleClose2();
                  }}
                  className="drop-header-menuitem"
                >
                  Profile
                </Index.MenuItem>
              </Link>
              <Link to="/admin/change-password">
                <Index.MenuItem
                  onClick={handleClose2}
                  className="drop-header-menuitem"
                >
                  {" "}
                  Change Password
                </Index.MenuItem>
              </Link>
              <Index.MenuItem
                onClick={() => {
                  handleClose2();
                  handlelogout();
                }}
                className="drop-header-menuitem logout-profile"
              >
                Logout
              </Index.MenuItem>
            </Index.Menu>
          </Index.Box>
        </Index.Box>
      </header>
      <Index.Modal
        open={openSettingsModal}
        onClose={handleCloseSettingsModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <>
          <PagesIndex.Formik
            enableReinitialize
            onSubmit={handleSettingsSubmit}
            initialValues={initialValues}
            validationSchema={PagesIndex.generalSettingSchema}
            innerRef={formikRef}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              <Index.Box
                sx={style}
                className="modal-inner-main add-region-modal modal-inner"
              >
                <Index.Box className="modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="modal-title"
                    variant="h6"
                    component="h2"
                  >
                    General Settings
                  </Index.Typography>
                  <img
                    src={PagesIndex.Svg.cancel}
                    className="modal-close-icon"
                    onClick={() => {
                      setOpenSettingsModal(false);
                    }}
                  />
                </Index.Box>

                <Index.Box className="modal-body">
                  <Index.Stack
                    component="form"
                    spacing={2}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    <Index.Box className="input-box modal-input-box">
                      <Index.FormHelperText className="form-lable">
                        Facebook
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          name="facebook"
                          className="form-control"
                          placeholder="Add facebook link"
                          value={values?.facebook}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          error={
                            errors.facebook && touched.facebook ? true : false
                          }
                          helperText={
                            errors.facebook && touched.facebook
                              ? errors.facebook
                              : null
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box modal-input-box">
                      <Index.FormHelperText className="form-lable">
                        Twitter
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          name="twitter"
                          className="form-control"
                          placeholder="Add twitter link"
                          value={values?.twitter}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          error={
                            errors.twitter && touched.twitter ? true : false
                          }
                          helperText={
                            errors.twitter && touched.twitter
                              ? errors.twitter
                              : null
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box modal-input-box">
                      <Index.FormHelperText className="form-lable">
                        Instagram
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          name="instagram"
                          className="form-control"
                          placeholder="Add instagram link"
                          value={values?.instagram}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          error={
                            errors.instagram && touched.instagram ? true : false
                          }
                          helperText={
                            errors.instagram && touched.instagram
                              ? errors.instagram
                              : null
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box modal-input-box">
                      <Index.FormHelperText className="form-lable">
                        YouTube
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          name="youtube"
                          className="form-control"
                          placeholder="Add youtube link"
                          value={values?.youtube}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          error={
                            errors.youtube && touched.youtube ? true : false
                          }
                          helperText={
                            errors.youtube && touched.youtube
                              ? errors.youtube
                              : null
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box modal-input-box">
                      <Index.FormHelperText className="form-lable">
                        Contact 1
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          name="contact1"
                          className="form-control"
                          placeholder="Add contact number"
                          inputProps={{ maxLength: 10 }}
                          value={values?.contact1}
                          onChange={(e) => {
                            setFieldTouched("contact1");
                            handleChange(e);
                          }}
                          error={
                            errors.contact1 && touched.contact1 ? true : false
                          }
                          helperText={
                            errors.contact1 && touched.contact1
                              ? errors.contact1
                              : null
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box modal-input-box">
                      <Index.FormHelperText className="form-lable">
                        Contact 2
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          name="contact2"
                          className="form-control"
                          placeholder="Add contact number"
                          inputProps={{ maxLength: 10 }}
                          value={values?.contact2}
                          onChange={(e) => {
                            setFieldTouched("contact2");
                            handleChange(e);
                          }}
                          error={
                            errors.contact2 && touched.contact2 ? true : false
                          }
                          helperText={
                            errors.contact2 && touched.contact2
                              ? errors.contact2
                              : null
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box modal-input-box">
                      <Index.FormHelperText className="form-lable">
                        Email
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          name="email"
                          className="form-control"
                          placeholder="Add email"
                          value={values?.email}
                          onChange={(e) => {
                            setFieldTouched("email");
                            handleChange(e);
                          }}
                          error={errors.email && touched.email ? true : false}
                          helperText={
                            errors.email && touched.email ? errors.email : null
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box modal-input-box">
                      <Index.FormHelperText className="form-lable">
                        Address
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextareaAutosize
                          fullWidth
                          id="fullWidth"
                          className="form-control form-text-area"
                          minRows={3}
                          name="address"
                          placeholder="Add address"
                          value={values?.address}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          error={
                            errors.address && touched.address ? true : false
                          }
                        />
                        <Index.FormHelperText error>
                          {errors.address && touched.address
                            ? errors.address
                            : null}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box modal-input-box">
                      <Index.FormHelperText className="form-lable">
                        Years of experience
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          name="exp"
                          className="form-control"
                          placeholder="Add years of experience"
                          value={values?.exp}
                          onChange={(e) => {
                            setFieldTouched("exp");
                            handleChange(e);
                          }}
                          error={errors.exp && touched.exp ? true : false}
                          helperText={
                            errors.exp && touched.exp ? errors.exp : null
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box modal-input-box">
                      <Index.FormHelperText className="form-lable">
                        No of theater screens
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          name="noOfScreens"
                          className="form-control"
                          placeholder="Add no of theater screens"
                          value={values?.noOfScreens}
                          onChange={(e) => {
                            setFieldTouched("noOfScreens");
                            handleChange(e);
                          }}
                          error={
                            errors.noOfScreens && touched.noOfScreens
                              ? true
                              : false
                          }
                          helperText={
                            errors.noOfScreens && touched.noOfScreens
                              ? errors.noOfScreens
                              : null
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="modal-user-btn-flex">
                      <Index.Box className="discard-btn-main btn-main-primary">
                        <Index.Box className="common-button blue-button res-blue-button">
                          <Index.Button
                            variant="contained"
                            disableRipple
                            className="no-text-decoration"
                            onClick={handleCloseSettingsModal}
                          >
                            Discard
                          </Index.Button>
                          <Index.Button
                            type="submit"
                            variant="contained"
                            disableRipple
                            className="no-text-decoration"
                          >
                            <img
                              src={PagesIndex.Svg.save}
                              className="user-save-icon"
                            ></img>
                            Save
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Stack>
                </Index.Box>
              </Index.Box>
            )}
          </PagesIndex.Formik>
        </>
      </Index.Modal>
    </div>
  );
}

export default Header;
