import axios from "axios";

// const API_ENDPOINT = "http://35.177.56.74:3035/api";
// export const IMAGES_API_ENDPOINT = "http://35.177.56.74:3035/uploads";
// const API_ENDPOINT = "https://vistaconnplex.appworkdemo.com/api";
// export const IMAGES_API_ENDPOINT =
//   "https://vistaconnplex.appworkdemo.com/uploads";
const API_ENDPOINT = "https://backend.theconnplex.com/api";
export const IMAGES_API_ENDPOINT = "https://backend.theconnplex.com/uploads";
// const API_ENDPOINT = "http://192.168.29.136:3035/api";

const DataService = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    // "Content-type": "application/x-www-form-urlencoded",
    // Accept: "application/json",
  },
});

// DataService.interceptors.request.use(
//   async function (config) {
//     try {
//       const userToekn = localStorage.getItem("token");
//       config.headers.auth = userToekn;
//     } catch (error) {
//       console.log(error);
//     }
//     return config;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );

export default DataService;
