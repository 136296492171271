import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./UserMovieReviewList.css";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const UserMovieReviewList = () => {
  const { adminLoginData } = PagesIndex.useSelector(
    (state) => state.admin.AdminSlice
  );
  const [movieList, setMovieList] = useState([]);
  const [movieReviewbookingsList, setMovieReviewList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");

  // State for pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedMovie, setSelectedMovie] = useState("");

  useEffect(() => {
    getUserReviewList(selectedMovie);
  }, [selectedMovie]);
  useEffect(() => {
    getMoviesList();
  }, []);
  const getMoviesList = () => {
    PagesIndex.DataService.get(PagesIndex.Api.GET_MOVIES)
      .then((res) => {
        if (res?.data?.status === 200) {
          setMovieList(res?.data?.data);
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.mes);
      });
  };
  const requestSearch = (searched) => {
    let filteredData = movieReviewbookingsList.filter(
      (data) =>
        data?.movieId?.name?.toLowerCase().includes(searched?.toLowerCase()) ||
        `${data?.userId?.firstName?.toLowerCase()} ${data?.userId?.lastName?.toLowerCase()}`?.includes(
          searched?.toLowerCase()
        )
    );
    setFilteredData(filteredData);
  };
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage - 1);
  };

  const getUserReviewList = (selectedMovie) => {
    PagesIndex.DataService.get(
      `${PagesIndex.Api.GET_RATE_REVIEW_LIST}?movieId=${selectedMovie || ""}`
    )
      .then((res) => {
        setMovieReviewList(res?.data?.data);
        setFilteredData(res?.data?.data);
      })
      .catch((err) => {
        setMovieReviewList(err?.response?.data?.data);
        setFilteredData(err?.response?.data?.data);
      });
  };
  const handleStatus = (e, id) => {
    const data = {
      id: id,
      isActive: e.target.checked,
    };
    PagesIndex.DataService.post(
      PagesIndex.Api.ACTIVE_DEACTIVE_USER_REVIEW,
      data
    )
      .then((res) => {
        if (res?.data?.status === 200) {
          PagesIndex.toast.success(res.data.message);
          getUserReviewList();
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };
  const handleOpen = (e, msg) => {
    setOpen(true);
    setData(msg);
  };
  const handleClose = () => {
    setOpen(false);
    setData("");
  };
  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box user-reviews">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  User Reviews
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Index.Box className="form-group">
                  <Index.Select
                    fullWidth
                    id="fullWidth"
                    name="category"
                    className="form-control"
                    displayEmpty
                    value={selectedMovie}
                    onChange={(e) => {
                      setSelectedMovie(e.target.value);
                    }}
                    renderValue={
                      selectedMovie ? undefined : () => "Select Movie"
                    }
                  >
                    <Index.MenuItem value={""}>All</Index.MenuItem>
                    {movieList.map((data) => {
                      return (
                        <Index.MenuItem value={data?._id}>
                          {data?.name}
                        </Index.MenuItem>
                      );
                    })}
                  </Index.Select>
                </Index.Box>
                <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => {
                      requestSearch(e.target.value);
                    }}
                  />
                </Search>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main one-line-table region-manage-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell width="25%">User Details</Index.TableCell>
                    <Index.TableCell width="20%">Movie</Index.TableCell>
                    <Index.TableCell width="20%">Movie Rating</Index.TableCell>
                    <Index.TableCell width="10%">
                      Conpplex Rating
                    </Index.TableCell>
                    <Index.TableCell width="10%">Review Date</Index.TableCell>
                    {adminLoginData?.roleId?.permissions?.includes(
                      "movie_review_edit"
                    ) && <Index.TableCell width="10%" align="center">Status</Index.TableCell>}
                    <Index.TableCell width="10%" align="right">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody>
                  {filteredData?.length ? (
                    filteredData?.map((item, index) => (
                      <Index.TableRow
                        // className="inquiry-list"
                        key={item?._id}
                      // onClick={(e) => handleOpen(e, item)}
                      >
                        <Index.TableCell>
                          {item?.userId?.firstName ?
                            `${item?.userId?.firstName} ${item?.userId?.lastName}` : "User"}
                        </Index.TableCell>
                        <Index.TableCell>
                          {item?.movieId?.name ? item?.movieId?.name : "-"}
                        </Index.TableCell>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="table-td"
                        >
                          {item?.movieRate ? item?.movieRate : "-"}
                        </Index.TableCell>
                        <Index.TableCell>
                          {item?.connplexRate ? item?.connplexRate : "-"}
                        </Index.TableCell>
                        <Index.TableCell>
                          {item?.createdAt
                            ? PagesIndex.moment(item?.createdAt).format(
                              "DD/MM/YYYY"
                            )
                            : "-"}
                          <br />
                          {item?.createdAt
                            ? PagesIndex.moment(item?.createdAt).format(
                              "hh:mm A"
                            )
                            : "-"}
                        </Index.TableCell>
                        {adminLoginData?.roleId?.permissions?.includes(
                          "movie_review_edit"
                        ) && (
                            <Index.TableCell align="center">
                              <PagesIndex.CustomToggleButton
                                defaultChecked={item?.isActive}
                                onChange={(e) => {
                                  handleStatus(e, item?._id);
                                }}
                              />
                            </Index.TableCell>
                          )}
                        <Index.TableCell align="right">
                          <Index.IconButton onClick={(e) => handleOpen(e, item)}>
                            <Index.Visibility />
                          </Index.IconButton>
                        </Index.TableCell>
                      </Index.TableRow>
                    ))
                  ) : (
                    <Index.TableRow>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="no-data-in-list"
                        colSpan={15}
                        align="center"
                      >
                        No data available
                      </Index.TableCell>
                    </Index.TableRow>
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Pagination
                  count={Math.ceil(filteredData.length / 10)}
                  page={currentPage + 1}
                  onChange={handleChangePage}
                />
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box
          sx={style}
          className="modal-inner-main add-role-modal modal-inner"
        >
          <Index.Box className="modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="modal-title"
              variant="h6"
              component="h2"
            >
              View Details
            </Index.Typography>
            <img
              src={PagesIndex.Svg.cancel}
              className="modal-close-icon"
              onClick={handleClose}
            />
          </Index.Box>
          <Index.Box className="modal-body">
            <Index.Box className="booking-detail-inner">
              <Index.Box className="booking-detail-img">
                <img
                  src={
                    data?.movieId?.poster
                      ? `${PagesIndex.IMAGES_API_ENDPOINT}/${data?.movieId?.poster}`
                      : PagesIndex.Png.NoImageAvailable
                  }
                  alt=""
                />
              </Index.Box>
              <Index.Box className="booking-detail-right">
                <Index.Box className="log-data">
                  {/* <Index.Box className="log-title">Review:</Index.Box> */}
                  <Index.Box className="log-text">
                    <Index.Box className="log-text-title" component="span">
                      User Name :
                    </Index.Box>{" "}
                    {data?.firstName
                      ? `${data?.userId?.firstName} ${data?.userId?.lastName}`
                      : "User"}
                  </Index.Box>
                  <Index.Box className="log-text">
                    <Index.Box className="log-text-title" component="span">
                      Movie Name :
                    </Index.Box>{" "}
                    {data?.movieId ? data?.movieId?.name : "-"}
                  </Index.Box>
                  <Index.Box className="log-text">
                    <Index.Box className="log-text-title" component="span">
                      Reviewed On :
                    </Index.Box>{" "}
                    {data?.createdAt
                      ? PagesIndex.moment(data?.createdAt).format(
                        "DD/MM/YYYY hh:mm A"
                      )
                      : "-"}
                  </Index.Box>
                  <Index.Box className="log-text">
                    <Index.Box className="log-text-title" component="span">
                      Connplex Rating :
                    </Index.Box>{" "}
                    {data?.connplexRate ? data?.connplexRate : "-"}
                  </Index.Box>
                  <Index.Box className="log-text">
                    <Index.Box className="log-text-title" component="span">
                      Movie Rating :
                    </Index.Box>{" "}
                    {data?.movieRate ? data?.movieRate : "-"}
                  </Index.Box>
                  <Index.Box className="log-text">
                    <Index.Box className="log-text-title" component="span">
                      Movie Review :
                    </Index.Box>{" "}
                    {console.log(data)}
                    {data?.movieReview ? data?.movieReview : "-"}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {/* <Index.Box className="modal-user-btn-flex">
              <Index.Box className="discard-btn-main btn-main-primary">
                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.Button
                    variant="contained"
                    disableRipple
                    className="no-text-decoration"
                    onClick={handleClose}
                  >
                    Close
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box> */}
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default UserMovieReviewList;
