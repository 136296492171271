import React from "react";

import { Outlet } from "react-router-dom";
import PagesIndex from "../../container/PagesIndex";

const PrivateLayout = ({ children }) => {
  const location = PagesIndex.useLocation();
  const token = PagesIndex.useSelector((state) => state.admin.AdminSlice.token);

  const isAdminLoggedIn = (token) => {
    if (!token) return false;
    PagesIndex.DataService.defaults.headers.common.auth = token;
    return true;
  };

  return isAdminLoggedIn(token) ? (
    <Outlet />
  ) : (
    <PagesIndex.Navigate to="/" state={{ from: location }} replace={true} />
  );
};

export default PrivateLayout;
