import { createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../../config/DataService";
import { toast } from "react-toastify";
import { Api } from "../../../config/Api";

export const adminLogin = createAsyncThunk("admin/adminLogin", async (data) => {
  try {
    const response = await DataService.post(Api.ADMIN_LOGIN, data);
    toast.success(response?.data?.message);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});

export const adminProfile = createAsyncThunk(
  "admin/adminEditProfile",
  async (data) => {
    try {
      const response = await DataService.get(Api.PROFILE_INFO, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  }
);

export const adminEditProfile = createAsyncThunk(
  "admin/adminEditProfile",
  async (data) => {
    try {
      const response = await DataService.post(Api.PROFILE_UPDATE, data);
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
