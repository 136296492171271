import { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./RolePermissionManagement.css";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const RolePermissionManagement = () => {
  let initialValues = {
    roleName: "",
  };
  const { adminLoginData } = PagesIndex.useSelector(
    (state) => state.admin.AdminSlice
  );
  const modules = [
    { tag: "role", title: "Role Permission" },
    { tag: "user", title: "User" },
    { tag: "region", title: "Region" },
    { tag: "slider", title: "Slider" },
    { tag: "cinema", title: "Cinema" },
    { tag: "movie", title: "Movie" },
    { tag: "movie_review", title: "Movie Reviews" },
    { tag: "actor", title: "Actors" },
    { tag: "applyForFranchise", title: "Franchise" },
    { tag: "food_beverages", title: "Foods And Beverages" },
    { tag: "bookings", title: "Bookings" },
    { tag: "transaction", title: "Transaction History" },
    { tag: "contactUs", title: "Contact us" },
    { tag: "gallery", title: "Gallery" },
    { tag: "trial_subs", title: "20 Days Trial" },
    { tag: "faq", title: "FAQ" },
    { tag: "sub_admin", title: "Sub Admin" },
  ];

  const [currentPage, setCurrentPage] = useState(0);
  const [openAdd, setOpenAdd] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [id, setId] = useState("");
  // const [roleName, setRoleName] = useState("");
  // const [roleError, setRoleError] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [addOrEdit, setAddOrEdit] = useState("Add");
  const [permissions, setPermissions] = useState([]);
  useEffect(() => {
    getRolePermissionList();
  }, []);

  const handleOpen = (mode) => {
    setAddOrEdit(mode);
    setOpenAdd(true);
  };
  const handleClose = (e) => {
    setId("");
    setPermissions([]);
    setOpenAdd(false);
  };
  const handleDeleteOpen = (id) => {
    setId(id);
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setId("");
  };
  const checkUncheckAllType = (action, type) => {
    if (action === "add") {
      let updatedData = modules?.map((ele) => {
        return ele?.tag + type;
      });
      setPermissions((prev) => [...prev, ...updatedData]);
    } else {
      const data = modules?.map((ele) => {
        return ele?.tag + type;
      });
      const removedArray = permissions.filter((el) => !data.includes(el));
      setPermissions(removedArray);
    }
  };
  const onChangeCheckBox = (value) => {
    if (permissions?.includes(value)) {
      const newData = permissions?.filter(function (item) {
        return item !== value;
      });
      setPermissions(newData);
    } else {
      const newData = [...permissions];
      newData.push(value);
      setPermissions(newData);
    }
  };
  // Search on table
  const requestSearch = (searched) => {
    let filteredData = roleList.filter((data) =>
      data?.role?.toLowerCase().includes(searched?.toLowerCase())
    );
    setFilteredData(filteredData);
  };
  const getRolePermissionList = () => {
    PagesIndex.DataService.get(PagesIndex.Api.GET_ROLEPERMISSION)
      .then((res) => {
        setRoleList(res.data.data);
        setFilteredData(res.data.data);
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };
  const handleSubmit = (values) => {
    let obj = {
      id,
      role: values?.roleName,
      permissions,
    };
    if (id && id !== "") {
      PagesIndex.DataService.post(PagesIndex.Api.EDIT_ROLEPERMISSION, obj)
        .then((res) => {
          PagesIndex.toast.success(res.data.message);
          handleClose();
          getRolePermissionList();
        })
        .catch((err) => {
          PagesIndex.toast.error(err.response.data.message);
        });
    }
    if (!id && id === "") {
      delete obj.id;
      PagesIndex.DataService.post(PagesIndex.Api.ADD_ROLEPERMISSION, obj)
        .then((res) => {
          PagesIndex.toast.success(res.data.message);
          handleClose();
          getRolePermissionList();
        })
        .catch((err) => {
          PagesIndex.toast.error(err.response.data.message);
        });
    }
  };
  const handleRemove = () => {
    PagesIndex.DataService.get(PagesIndex.Api.DELETE_ROLEPERMISSION + `/${id}`)
      .then((res) => {
        PagesIndex.toast.success(res.data.message);
        handleDeleteClose();
        getRolePermissionList();
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage - 1);
  };
  return (
    <>
      <PagesIndex.Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={PagesIndex.rolePermissionSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          resetForm,
        }) => (
          <Index.Box className="">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      Role Permission Management
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="d-flex align-items-center res-set-search">
                    <Search className="search ">
                      <StyledInputBase
                        placeholder="Search"
                        inputProps={{ "aria-label": "search" }}
                        onChange={(e) => requestSearch(e.target.value)}
                      />
                    </Search>
                    {adminLoginData?.roleId?.permissions?.includes(
                      "role_add"
                    ) && (
                      <Index.Box className="common-button blue-button res-blue-button">
                        <Index.Button
                          variant="contained"
                          disableRipple
                          className="no-text-decoration"
                          onClick={() => {
                            resetForm();
                            handleOpen("Add");
                          }}
                        >
                          Add Role
                        </Index.Button>
                      </Index.Box>
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  <Index.Table
                    aria-label="simple table"
                    className="table-design-main one-line-table"
                  >
                    <Index.TableHead>
                      <Index.TableRow>
                        <Index.TableCell>Role</Index.TableCell>
                        <Index.TableCell align="right">Action</Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody>
                      {filteredData?.length ? (
                        filteredData
                          ?.slice(currentPage * 10, currentPage * 10 + 10)
                          ?.map((item, index) => (
                            <Index.TableRow
                              // className="inquiry-list"
                              key={item?._id}
                            >
                              <Index.TableCell>
                                {item?.role ? item?.role : "-"}
                              </Index.TableCell>
                              {(adminLoginData?.roleId?.permissions?.includes(
                                "role_edit"
                              ) ||
                                adminLoginData?.roleId?.permissions?.includes(
                                  "role_delete"
                                )) && (
                                <Index.TableCell align="right">
                                  {adminLoginData?.roleId?.permissions?.includes(
                                    "role_edit"
                                  ) && (
                                    <Index.IconButton
                                      onClick={() => {
                                        setId(item?._id);
                                        setPermissions(item?.permissions);
                                        setFieldValue("roleName", item?.role);
                                        handleOpen("Edit");
                                      }}
                                    >
                                      <Index.EditIcon />
                                    </Index.IconButton>
                                  )}
                                  {adminLoginData?.roleId?.permissions?.includes(
                                    "role_delete"
                                  ) && (
                                    <Index.IconButton
                                      onClick={() =>
                                        handleDeleteOpen(item?._id)
                                      }
                                    >
                                      <Index.DeleteIcon />
                                    </Index.IconButton>
                                  )}
                                </Index.TableCell>
                              )}
                            </Index.TableRow>
                          ))
                      ) : (
                        <Index.TableRow>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="no-data-in-list"
                            colSpan={15}
                            align="center"
                          >
                            No data available
                          </Index.TableCell>
                        </Index.TableRow>
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="pagination-design flex-end">
                <Index.Stack spacing={2}>
                  <Index.Box className="pagination-count">
                    <Index.Pagination
                      count={Math.ceil(filteredData.length / 10)}
                      page={currentPage + 1}
                      onChange={handleChangePage}
                    />
                  </Index.Box>
                </Index.Stack>
              </Index.Box>
            </Index.Box>
            <Index.Modal
              open={openAdd}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="modal"
            >
              <Index.Box
                sx={style}
                className="modal-inner-main add-role-modal modal-inner"
              >
                <Index.Box className="modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {addOrEdit} Role
                  </Index.Typography>
                  <img
                    src={PagesIndex.Svg.cancel}
                    className="modal-close-icon"
                    onClick={handleClose}
                  />
                </Index.Box>
                <Index.Box className="modal-body">
                  <Index.Stack
                    component="form"
                    spacing={2}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    <Index.Box className="input-box modal-input-box">
                      <Index.FormHelperText className="form-lable">
                        Role
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          name="roleName"
                          placeholder="Add role name"
                          value={values?.roleName}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          error={
                            errors.roleName && touched.roleName ? true : false
                          }
                          helperText={
                            errors.roleName && touched.roleName
                              ? errors.roleName
                              : null
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box modal-input-box">
                      <Index.FormHelperText className="form-lable">
                        Permissions
                      </Index.FormHelperText>
                      <Index.Table aria-label="simple table" className="table">
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            ></Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              <>
                                <p>View</p>
                                <Index.Checkbox
                                  checked={
                                    permissions.filter((data) =>
                                      data.includes("_view")
                                    ).length === modules.length
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      checkUncheckAllType("add", "_view");
                                    } else {
                                      checkUncheckAllType("remove", "_view");
                                    }
                                  }}
                                />
                              </>
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              <>
                                <p>Add</p>
                                <Index.Checkbox
                                  checked={
                                    permissions.filter((data) =>
                                      data.includes("_add")
                                    ).length === modules.length
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      checkUncheckAllType("add", "_add");
                                    } else {
                                      checkUncheckAllType("remove", "_add");
                                    }
                                  }}
                                />
                              </>
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              <>
                                <p>Edit</p>
                                <Index.Checkbox
                                  checked={
                                    permissions.filter((data) =>
                                      data.includes("_edit")
                                    ).length === modules.length
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      checkUncheckAllType("add", "_edit");
                                    } else {
                                      checkUncheckAllType("remove", "_edit");
                                    }
                                  }}
                                />
                              </>
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              <>
                                <p>Delete</p>
                                <Index.Checkbox
                                  checked={
                                    permissions.filter((data) =>
                                      data.includes("_delete")
                                    ).length === modules.length
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      checkUncheckAllType("add", "_delete");
                                    } else {
                                      checkUncheckAllType("remove", "_delete");
                                    }
                                  }}
                                />
                              </>
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {modules.length ? (
                            modules.map((row, index) => (
                              <Index.TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  {row.title}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  align="center"
                                >
                                  <Index.Checkbox
                                    checked={
                                      permissions?.includes(`${row?.tag}_view`)
                                        ? true
                                        : false
                                    }
                                    onChange={() =>
                                      onChangeCheckBox(`${row?.tag}_view`)
                                    }
                                  />
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  align="center"
                                >
                                  <Index.Checkbox
                                    checked={
                                      permissions?.includes(`${row?.tag}_add`)
                                        ? true
                                        : false
                                    }
                                    onChange={() =>
                                      onChangeCheckBox(`${row?.tag}_add`)
                                    }
                                  />
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  align="center"
                                >
                                  <Index.Checkbox
                                    checked={
                                      permissions?.includes(`${row?.tag}_edit`)
                                        ? true
                                        : false
                                    }
                                    onChange={() =>
                                      onChangeCheckBox(`${row?.tag}_edit`)
                                    }
                                  />
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  align="center"
                                >
                                  <Index.Checkbox
                                    checked={
                                      permissions?.includes(
                                        `${row?.tag}_delete`
                                      )
                                        ? true
                                        : false
                                    }
                                    onChange={() =>
                                      onChangeCheckBox(`${row?.tag}_delete`)
                                    }
                                  />
                                </Index.TableCell>
                              </Index.TableRow>
                            ))
                          ) : (
                            <Index.Typography
                              align="center"
                              className="no_data_found"
                            >
                              No Data Found
                            </Index.Typography>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                      {/* </Index.TableContainer> */}
                    </Index.Box>
                    <Index.Box className="modal-user-btn-flex">
                      <Index.Box className="discard-btn-main btn-main-primary">
                        <Index.Box className="common-button blue-button res-blue-button">
                          <Index.Button
                            variant="contained"
                            disableRipple
                            className="no-text-decoration"
                            onClick={handleClose}
                          >
                            Discard
                          </Index.Button>
                          <Index.Button
                            type="submit"
                            variant="contained"
                            disableRipple
                            className="no-text-decoration"
                          >
                            <img
                              src={PagesIndex.Svg.save}
                              className="user-save-icon"
                            ></img>
                            Save
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Stack>
                </Index.Box>
              </Index.Box>
            </Index.Modal>
            <PagesIndex.DeleteModal
              deleteOpen={deleteOpen}
              handleDeleteClose={handleDeleteClose}
              handleDeleteRecord={handleRemove}
            />
          </Index.Box>
        )}
      </PagesIndex.Formik>
    </>
  );
};
export default RolePermissionManagement;
