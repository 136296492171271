import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./CinemaManagement.css";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const CinemaManagement = () => {
  let initialValues = {
    cinemaName: "",
    displayName: "",
    poster: "",
    images: [],
    address: "",
    regionId: "",
    emailId: "",
    mobileNumber: "",
    googleUrl: "",
    cinemaWebServiceUrl: "",
    cinemaWebServiceUrl2: "",
  };
  const formikRef = useRef();
  const { adminLoginData } = PagesIndex.useSelector(
    (state) => state.admin.AdminSlice
  );

  const [regionList, setRegionList] = useState([]);
  const [cinemaList, setCinemaList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [addOrEdit, setAddOrEdit] = useState("Add");
  const [id, setId] = useState("");

  // State for pagination
  const [currentPage, setCurrentPage] = useState(0);

  // State for searching and set data
  const [addOpen, setAddOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [images, setImages] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);

  useEffect(() => {
    getCinemaList();
    getRegionList();
  }, []);
  const handleOpen = (mode) => {
    setAddOrEdit(mode);
    setAddOpen(true);
    formikRef.current?.resetForm();
  };
  const handleClose = () => {
    setId("");
    setImageUrl("");
    setImageURLs([]);
    setAddOpen(false);
  };
  const handleDeleteOpen = (id) => {
    setId(id);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setId("");
  };
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage - 1);
  };
  // Search on table
  const requestSearch = (searched) => {
    let filteredData = cinemaList.filter((data) =>
      data?.cinemaName?.toLowerCase().includes(searched?.toLowerCase())
    );
    setFilteredData(filteredData);
  };
  // get region list
  const getRegionList = () => {
    PagesIndex.DataService.get(PagesIndex.Api.GET_REGION)
      .then((res) => {
        setRegionList(res?.data?.data);
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };
  // get cinema list
  const getCinemaList = () => {
    PagesIndex.DataService.get(PagesIndex.Api.GET_CINEMA)
      .then((res) => {
        setCinemaList(res?.data?.data);
        setFilteredData(res?.data?.data);
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };

  const handleCinemaSubmit = (values) => {
    const urlEncoded = new FormData();
    if (id) {
      urlEncoded.append("id", id);
    }
    urlEncoded.append("cinemaName", values?.cinemaName);
    urlEncoded.append("displayName", values?.displayName);
    urlEncoded.append("address", values?.address);
    urlEncoded.append("regionId", values?.regionId);
    urlEncoded.append("mobileNumber", values?.mobileNumber);
    urlEncoded.append("emailId", values?.emailId);
    urlEncoded.append("poster", values?.poster);
    urlEncoded.append("googleUrl", values?.googleUrl);
    urlEncoded.append("cinemaWebServiceUrl", values?.cinemaWebServiceUrl);
    urlEncoded.append("cinemaWebServiceUrl2", values?.cinemaWebServiceUrl2);
    [...values?.images]?.map((e) => {
      urlEncoded.append("images", e);
    });
    PagesIndex.DataService.post(PagesIndex.Api.ADD_EDIT_CINEMA, urlEncoded)
      .then((res) => {
        PagesIndex.toast.success(res.data.message);
        handleClose();
        getCinemaList();
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };
  const handleCinemaRemove = () => {
    PagesIndex.DataService.post(PagesIndex.Api.DELETE_CINEMA, { id: id })
      .then((res) => {
        PagesIndex.toast.success(res.data.message);
        handleDeleteClose();
        getCinemaList();
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };
  return (
    <PagesIndex.Formik
      enableReinitialize
      onSubmit={handleCinemaSubmit}
      initialValues={initialValues}
      validationSchema={
        addOrEdit === "Add"
          ? PagesIndex.cinemaSchema
          : PagesIndex.cinemaEditSchema
      }
      innerRef={formikRef}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldTouched,
        setFieldValue,
        resetForm,
      }) => (
        <>
          <Index.Box className="">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      Cinema Management
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="d-flex align-items-center res-set-search">
                    <Search className="search ">
                      <StyledInputBase
                        placeholder="Search"
                        inputProps={{ "aria-label": "search" }}
                        onChange={(e) => requestSearch(e.target.value)}
                      />
                    </Search>
                    {adminLoginData?.roleId?.permissions?.includes(
                      "cinema_add"
                    ) && (
                      <Index.Box className="common-button blue-button res-blue-button">
                        <Index.Button
                          variant="contained"
                          disableRipple
                          className="no-text-decoration"
                          onClick={() => {
                            handleOpen("Add");
                          }}
                        >
                          Add Cinema
                        </Index.Button>
                      </Index.Box>
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  <Index.Table
                    aria-label="simple table"
                    className="table-design-main one-line-table"
                  >
                    <Index.TableHead>
                      <Index.TableRow>
                        <Index.TableCell>Image</Index.TableCell>
                        <Index.TableCell>Cinema</Index.TableCell>
                        <Index.TableCell>Display Name</Index.TableCell>
                        <Index.TableCell>Email</Index.TableCell>
                        <Index.TableCell>Mobile Number</Index.TableCell>
                        {(adminLoginData?.roleId?.permissions?.includes(
                          "cinema_edit"
                        ) ||
                          adminLoginData?.roleId?.permissions?.includes(
                            "cinema_delete"
                          )) && (
                          <Index.TableCell align="right">
                            Action
                          </Index.TableCell>
                        )}
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody>
                      {filteredData?.length ? (
                        filteredData
                          ?.slice(currentPage * 10, currentPage * 10 + 10)
                          ?.map((item, index) => (
                            <Index.TableRow
                              // className="inquiry-list"
                              key={item?._id}
                            >
                              <Index.TableCell>
                                <Index.Box className="cinema_img">
                                  <img
                                    src={
                                      item?.poster
                                        ? `${PagesIndex.IMAGES_API_ENDPOINT}/${item?.poster}`
                                        : PagesIndex.Png.NoImageAvailable
                                    }
                                    onClick={handleClose}
                                    alt=""
                                  />
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell>
                                {item?.cinemaName ? item?.cinemaName : "-"}
                              </Index.TableCell>
                              <Index.TableCell>
                                {item?.displayName ? item?.displayName : "-"}
                              </Index.TableCell>
                              <Index.TableCell>
                                {item?.emailId ? item?.emailId : "-"}
                              </Index.TableCell>
                              <Index.TableCell>
                                {item?.mobileNumber ? item?.mobileNumber : "-"}
                              </Index.TableCell>
                              {(adminLoginData?.roleId?.permissions?.includes(
                                "cinema_edit"
                              ) ||
                                adminLoginData?.roleId?.permissions?.includes(
                                  "cinema_delete"
                                )) && (
                                <Index.TableCell align="right">
                                  {adminLoginData?.roleId?.permissions?.includes(
                                    "cinema_edit"
                                  ) && (
                                    <Index.IconButton
                                      onClick={(e) => {
                                        setId(item?._id);
                                        handleOpen("Edit");
                                        if (item?.poster) {
                                          setImageUrl(
                                            `${PagesIndex?.IMAGES_API_ENDPOINT}/${item?.poster}`
                                          );
                                        }
                                        for (let key in item) {
                                          if (key === "regionId") {
                                            setFieldValue(key, item[key]?._id);
                                          }
                                          if (key === "cinema_images") {
                                            const imageArr = item[key]?.map(
                                              (data) =>
                                                `${PagesIndex?.IMAGES_API_ENDPOINT}/${data}`
                                            );
                                            setImages(imageArr);
                                            setImageURLs(imageArr);
                                          }
                                          if (
                                            key !== "poster" &&
                                            key !== "regionId"
                                          ) {
                                            setFieldValue(key, item[key]);
                                          }
                                        }
                                      }}
                                    >
                                      <Index.EditIcon />
                                    </Index.IconButton>
                                  )}
                                  {adminLoginData?.roleId?.permissions?.includes(
                                    "cinema_delete"
                                  ) && (
                                    <Index.IconButton
                                      onClick={() =>
                                        handleDeleteOpen(item?._id)
                                      }
                                    >
                                      <Index.DeleteIcon />
                                    </Index.IconButton>
                                  )}
                                </Index.TableCell>
                              )}
                            </Index.TableRow>
                          ))
                      ) : (
                        <Index.TableRow>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="no-data-in-list"
                            colSpan={15}
                            align="center"
                          >
                            No data available
                          </Index.TableCell>
                        </Index.TableRow>
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="pagination-design flex-end">
                <Index.Stack spacing={2}>
                  <Index.Box className="pagination-count">
                    <Index.Pagination
                      count={Math.ceil(filteredData.length / 10)}
                      page={currentPage + 1}
                      onChange={handleChangePage}
                    />
                  </Index.Box>
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Modal
            open={addOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal"
          >
            <Index.Box
              sx={style}
              className="modal-inner-main add-cinema-modal modal-inner"
            >
              <Index.Box className="modal-header">
                <Index.Typography
                  id="modal-modal-title"
                  className="modal-title"
                  variant="h6"
                  component="h2"
                >
                  {addOrEdit} Cinema
                </Index.Typography>
                <img
                  src={PagesIndex.Svg.cancel}
                  className="modal-close-icon"
                  onClick={handleClose}
                />
              </Index.Box>
              <Index.Box className="modal-body">
                <Index.Stack
                  component="form"
                  spacing={2}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Cinema
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        name="cinemaName"
                        className="form-control"
                        placeholder="Add cinema name"
                        value={values?.cinemaName}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        error={
                          errors.cinemaName && touched.cinemaName ? true : false
                        }
                        helperText={
                          errors.cinemaName && touched.cinemaName
                            ? errors.cinemaName
                            : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Display name
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        name="displayName"
                        className="form-control"
                        placeholder="Add cinema display name"
                        value={values?.displayName}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        error={
                          errors.displayName && touched.displayName
                            ? true
                            : false
                        }
                        helperText={
                          errors.displayName && touched.displayName
                            ? errors.displayName
                            : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Address
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        name="address"
                        className="form-control"
                        placeholder="Add address"
                        value={values?.address}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        error={errors.address && touched.address ? true : false}
                        helperText={
                          errors.address && touched.address
                            ? errors.address
                            : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Cinema Cover Image
                    </Index.FormHelperText>
                    {/* {imageUrl && (
                      <img src={imageUrl} className="cinema-image" />
                    )}
                    <Index.Box className="form-group region_img_upload">
                      <Index.Box className="common-button grey-button change-profile">
                        <input
                          name="poster"
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={(event) => {
                            setFieldValue("poster", event.currentTarget.files[0]);
                            setImageUrl(
                              URL.createObjectURL(event.currentTarget.files[0])
                            );
                          }}
                        />
                        <Index.FormHelperText error>
                          {errors.poster && touched.poster ? errors.poster : null}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box> */}
                    <Index.Box className="file-upload-btn-main">
                      <Index.Button
                        variant="contained"
                        component="label"
                        className="file-upload-btn"
                      >
                        {imageUrl ? (
                          <img src={imageUrl} className="upload-profile-img" />
                        ) : (
                          <img
                            className="
                            upload-img"
                            src={PagesIndex.Svg.add}
                          />
                        )}
                        <input
                          hidden
                          accept="image/*"
                          name="poster"
                          type="file"
                          onChange={(e) => {
                            try {
                              setFieldValue("poster", e.currentTarget.files[0]);
                              setImageUrl(
                                URL.createObjectURL(e.currentTarget.files[0])
                              );
                            } catch (error) {
                              console.error(error);
                              e.currentTarget.value = null;
                            }
                          }}
                          error={errors.image && touched.image ? true : false}
                          helperText={
                            errors.image && touched.image ? errors.image : false
                          }
                        />
                      </Index.Button>
                    </Index.Box>
                    <Index.FormHelperText error>
                      {errors.poster && touched.poster ? errors.poster : null}
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Theatre Image
                    </Index.FormHelperText>
                    <Index.Box className="file-upload-btn-main multiple-images">
                      {imageURLs.length
                        ? imageURLs.map((data, key) => {
                            return (
                              <Index.Button
                                key={key}
                                variant="contained"
                                component="span"
                                className="file-upload-btn file-upload-btn-close"
                                onClick={() => {
                                  const imageArray = images?.filter(
                                    (data, index) => key !== index
                                  );
                                  const imageArrayForFile = images?.filter(
                                    (data, index) =>
                                      key !== index && typeof data === "object"
                                  );
                                  setFieldValue("images", imageArrayForFile);
                                  setImages(imageArray);
                                  const newImageUrls = [];
                                  imageArray.forEach((image) => {
                                    if (typeof image === "object") {
                                      newImageUrls.push(
                                        URL.createObjectURL(image)
                                      );
                                    } else {
                                      newImageUrls.push(image);
                                    }
                                  });
                                  setImageURLs(newImageUrls);
                                }}
                              >
                                <img
                                  src={data}
                                  className="upload-profile-img"
                                />
                              </Index.Button>
                            );
                          })
                        : ""}
                      <Index.Button
                        variant="contained"
                        component="label"
                        className="file-upload-btn"
                      >
                        <img
                          className="
                          upload-img"
                          src={PagesIndex.Svg.add}
                        />
                        <input
                          hidden
                          accept="image/*"
                          multiple
                          name="images"
                          type="file"
                          onChange={(e) => {
                            const keys = Object.keys(e.currentTarget.files);
                            const imageArray = keys?.map((data) => {
                              return e.currentTarget.files[data];
                            });
                            try {
                              setFieldValue("images", [
                                ...values?.images,
                                ...imageArray,
                              ]);
                              setImages((prev) => [...prev, ...imageArray]);
                              const newImageUrls = [];
                              [...values?.images, ...imageArray].forEach(
                                (image) =>
                                  newImageUrls.push(URL.createObjectURL(image))
                              );
                              setImageURLs((prev) => [
                                ...prev,
                                ...newImageUrls,
                              ]);
                            } catch (error) {
                              console.error(error);
                              e.currentTarget.value = null;
                            }
                          }}
                          error={errors.images && touched.images ? true : false}
                          helperText={
                            errors.images && touched.images
                              ? errors.images
                              : false
                          }
                        />
                      </Index.Button>
                    </Index.Box>
                    <Index.FormHelperText error>
                      {errors.images && touched.images ? errors.images : null}
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Region
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.Select
                        fullWidth
                        id="fullWidth"
                        name="regionId"
                        className="form-control"
                        value={values?.regionId}
                        displayEmpty
                        renderValue={
                          values?.regionId ? undefined : () => "Select region"
                        }
                        onChange={handleChange}
                        error={
                          errors.regionId && touched.regionId ? true : false
                        }
                      >
                        {regionList.map((data, key) => {
                          return (
                            <Index.MenuItem value={data?._id} key={key}>
                              {data?.region}
                            </Index.MenuItem>
                          );
                        })}
                      </Index.Select>
                      <Index.FormHelperText error>
                        {errors.regionId && touched.regionId
                          ? errors.regionId
                          : null}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Email
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        name="emailId"
                        className="form-control"
                        placeholder="Add email"
                        value={values?.emailId}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        error={errors.emailId && touched.emailId ? true : false}
                        helperText={
                          errors.emailId && touched.emailId
                            ? errors.emailId
                            : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Phone Number
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        name="mobileNumber"
                        className="form-control"
                        placeholder="Add phoneNumber"
                        inputProps={{ maxLength: 10 }}
                        value={values?.mobileNumber}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        error={
                          errors.mobileNumber && touched.mobileNumber
                            ? true
                            : false
                        }
                        helperText={
                          errors.mobileNumber && touched.mobileNumber
                            ? errors.mobileNumber
                            : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Google URL
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        name="googleUrl"
                        className="form-control"
                        placeholder="Add location URl"
                        value={values?.googleUrl}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        error={
                          errors.googleUrl && touched.googleUrl ? true : false
                        }
                        helperText={
                          errors.googleUrl && touched.googleUrl
                            ? errors.googleUrl
                            : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Cinema Web Service URL 1
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        name="cinemaWebServiceUrl"
                        className="form-control"
                        placeholder="Add web service url 1"
                        inputProps={{ maxLength: 100 }}
                        value={values?.cinemaWebServiceUrl}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        error={
                          errors.cinemaWebServiceUrl &&
                          touched.cinemaWebServiceUrl
                            ? true
                            : false
                        }
                        helperText={
                          errors.cinemaWebServiceUrl &&
                          touched.cinemaWebServiceUrl
                            ? errors.cinemaWebServiceUrl
                            : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Cinema Web Service URL 2
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        name="cinemaWebServiceUrl2"
                        className="form-control"
                        placeholder="Add web service url 2"
                        inputProps={{ maxLength: 100 }}
                        value={values?.cinemaWebServiceUrl2}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        error={
                          errors.cinemaWebServiceUrl2 &&
                          touched.cinemaWebServiceUrl2
                            ? true
                            : false
                        }
                        helperText={
                          errors.cinemaWebServiceUrl2 &&
                          touched.cinemaWebServiceUrl2
                            ? errors.cinemaWebServiceUrl2
                            : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="modal-user-btn-flex">
                    <Index.Box className="discard-btn-main btn-main-primary">
                      <Index.Box className="common-button blue-button res-blue-button">
                        <Index.Button
                          variant="contained"
                          disableRipple
                          className="no-text-decoration"
                          onClick={handleClose}
                        >
                          Discard
                        </Index.Button>
                        <Index.Button
                          type="submit"
                          variant="contained"
                          disableRipple
                          className="no-text-decoration"
                        >
                          <img
                            src={PagesIndex.Svg.save}
                            className="user-save-icon"
                          ></img>
                          Save
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Modal>
          <PagesIndex.DeleteModal
            deleteOpen={deleteOpen}
            handleDeleteClose={handleDeleteClose}
            handleDeleteRecord={handleCinemaRemove}
          />
        </>
      )}
    </PagesIndex.Formik>
  );
};

export default CinemaManagement;
