// import backlogin from "./images/Png/backlogin.png";
import backlogin from "./images/Png/backlogin.png";
import user from "./images/Png/user.png";
import connplexlogo from "./images/Png/connplexlogo.png";
import NoImageAvailable from "./images/Png/No_image_available.png";
const Png = {
  backlogin,
  user,
  connplexlogo,
  NoImageAvailable
};

export default Png;
