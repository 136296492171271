import * as Yup from "yup";

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9.-]+$/;
const phoneRegExp =
  /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{3,})$/;
const otpRegex = /^\d{4}$/;
const nameRegex = /^(?!\s).+(?<!\s)$/gm;
const locationRegex = /@(-?\d+\.\d+),(-?\d+\.\d+),(\d+\.?\d?)+z/;
const urlRegex =
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
const charSpace = (value) =>
  /^[^-\s][a-zA-Z\s]+$/.test(value) || value.length === 0;

const getCharacterValidationError = (str) => {
  return `Your password must have at least 1 ${str} character`;
};

export const loginSchema = Yup.object().shape({
  email: Yup.string("Enter your email")
    .required("Email is required")
    .email("Email is not valid")
    .matches(emailRegex, "Enter a valid email"),
  password: Yup.string("Enter your password")
    .required("Password is required")
    .min(6, "Password must be at least 8 characters")
    .max(16, "Password must be at most 16 characters")
    .matches(/[0-9]/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
    .matches(/[^\w\s]/, getCharacterValidationError("special")),
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string("Please enter your email address")
    .required("Email is required")
    .email("Email is not valid")
    .matches(emailRegex, "Email is not valid"),
});

export const otpSchema = Yup.object().shape({
  otp: Yup.string("Please enter your OTP")
    .required("OTP is required")
    .matches(otpRegex, "OTP is not valid"),
});

export const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string("Please enter New Password")
    .required("Please enter New Password")
    .min(6, "Password must be at least 8 characters")
    .max(16, "Password must be at most 16 characters")
    .matches(/[0-9]/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
    .matches(/[^\w\s]/, getCharacterValidationError("special")),

  confirmPassword: Yup.string("Please enter Confirm Password")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Please enter New confirm Password"),
});

export const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string("Please enter New Password").required(
    "Please enter New Password"
  ),
  newPassword: Yup.string("Please enter New Password")
    .required("Please enter New Password")
    .min(6, "Password must be at least 8 characters")
    .max(16, "Password must be at most 16 characters")
    .matches(passwordRegex, "Must contain one lowercase character"),

  confirmPassword: Yup.string("Please enter Confirm Password")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Please enter New confirm Password"),
});
export const subAdminAddScema = Yup.object().shape({
  name: Yup.string("Enter user name")
    .required("User name is required")
    .matches(nameRegex, "Enter valid user name"),
  email: Yup.string("Enter your email")
    .required("Email is required")
    .email("Email is not valid")
    .matches(emailRegex, "Enter a valid email"),
  mobileNumber: Yup.string("Enter your mobile number")
    .required("Mobile number is required")
    .matches(phoneRegExp, "Enter a valid mobile number"),
  password: Yup.string("Enter your password")
    .required("Password is required")
    .min(6, "Password must be at least 8 characters")
    .max(16, "Password must be at most 16 characters")
    .matches(/[0-9]/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
    .matches(/[^\w\s]/, getCharacterValidationError("special")),
  confirmPassword: Yup.string("Please enter Confirm Password")
    .required("Please enter confirm Password")
    .oneOf([Yup.ref("password")], "Passwords must match"),
  roleId: Yup.string("Please select role").required("Please select role"),
});
export const subAdminEditScema = Yup.object().shape({
  name: Yup.string("Enter user name")
    .required("User name is required")
    .matches(nameRegex, "Enter valid user name"),
  email: Yup.string("Enter your email")
    .required("Email is required")
    .email("Email is not valid")
    .matches(emailRegex, "Enter a valid email"),
  mobileNumber: Yup.string("Enter your mobile number")
    .required("Mobile number is required")
    .matches(phoneRegExp, "Enter a valid mobile number"),
  roleId: Yup.string("Please select role").required("Please select role"),
});

export const regionSchema = Yup.object().shape({
  regionName: Yup.string("Enter region name")
    .required("Region name is required")
    .matches(nameRegex, "Enter valid region name"),
  image: Yup.string().required("Please select image"),
});
export const regionEditSchema = Yup.object().shape({
  regionName: Yup.string("Enter region name")
    .required("Region name is required")
    .matches(nameRegex, "Enter valid region name"),
});

export const rolePermissionSchema = Yup.object().shape({
  roleName: Yup.string("Enter role name")
    .required("Role name is required")
    .matches(nameRegex, "Enter valid role name"),
});

export const cinemaSchema = Yup.object().shape({
  cinemaName: Yup.string("Enter cinema name")
    .required("Cinema name is required")
    .matches(nameRegex, "Enter valid cinema name"),
  displayName: Yup.string("Enter display name")
    .required("Cinema display name is required")
    .matches(nameRegex, "Enter valid display name"),
  address: Yup.string("Enter address").required("Cinema address is required"),
  poster: Yup.mixed()
    .required("Cover image is required")
    .test(
      "FILE_SIZE",
      "Uploaded file is too big.",
      (value) => !value || (value && value.size <= 2000000)
    ),
  images: Yup.mixed()
    .required("Images are required")
    .test("FILE_COUNT", "Upload theatre images", (values) => values?.length)
    .test("FILE_SIZE", "One of uploaded file is too big.", (values) => {
      const inValidFile = values?.filter((data) => data.size > 1000000);
      return !inValidFile.length;
    })
    .test("FILE_TYPE", "Unsupported file type found.", (values) => {
      const inValidFile = values?.filter((data) => {
        if (data.type === "image/jpeg") {
          return true;
        }
        if (data.type === "image/jpg") {
          return true;
        }
        if (data.type === "image/png") {
          return true;
        }
      });
      return inValidFile.length === values.length;
    }),
  regionId: Yup.string("Enter region").required("Region is required"),
  emailId: Yup.string("Enter your email")
    .required("Email is required")
    .email("Email is not valid")
    .matches(emailRegex, "Enter a valid email"),
  mobileNumber: Yup.string("Enter your phone number")
    .required("Phone number is required")
    .matches(phoneRegExp, "Enter a valid phone number"),
  googleUrl: Yup.string("Enter your location URL")
    .required("location URL is required")
    .matches(locationRegex, "Enter a valid location URL"),
  cinemaWebServiceUrl: Yup.string("Enter web service url 1")
    .required("Cinema web service url 1 is required")
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    ),
  cinemaWebServiceUrl2: Yup.string("Enter web service url 2")
    .required("Cinema web service url 2 is required")
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    ),
});

export const cinemaEditSchema = Yup.object().shape({
  cinemaName: Yup.string("Enter cinema name")
    .required("Cinema name is required")
    .matches(nameRegex, "Enter valid cinema name"),
  displayName: Yup.string("Enter display name")
    .required("Cinema display name is required")
    .matches(nameRegex, "Enter valid display name"),
  address: Yup.string("Enter address").required("Cinema address is required"),
  regionId: Yup.string("Enter region").required("Region is required"),
  emailId: Yup.string("Enter your email")
    .required("Email is required")
    .email("Email is not valid")
    .matches(emailRegex, "Enter a valid email"),
  mobileNumber: Yup.string("Enter your phone number")
    .required("Phone number is required")
    .matches(phoneRegExp, "Enter a valid phone number"),
  googleUrl: Yup.string("Enter your location URL")
    .required("location URL is required")
    .matches(locationRegex, "Enter a valid location URL"),
  cinemaWebServiceUrl: Yup.string("Enter web service url 1")
    .required("Cinema web service url 1 is required")
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    ),
  cinemaWebServiceUrl2: Yup.string("Enter web service url 2")
    .required("Cinema web service url 2 is required")
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    ),
});

export const movieSchema = Yup.object().shape({
  movieName: Yup.string("Enter movie name")
    .required("Movie name is required")
    .matches(nameRegex, "Enter valid cinema name"),
  description: Yup.string("Enter movie description").required(
    "Movie description is required"
  ),
  censorRating: Yup.string("Enter movie censor rating").required(
    "Movie censor rating is required"
  ),
  language: Yup.string("Enter language").required("Language is required"),
  category: Yup.string("Enter genre").required("Genre is required"),
  movieUrl: Yup.string("Enter youtube Url").required("Youtube Url is required"),
  releaseDate: Yup.string("Enter movie release date").required(
    "Movie release date is required"
  ),
  poster: Yup.mixed()
    .required("Poster is required")
    .test(
      "FILE_SIZE",
      "Uploaded file is too big.",
      (value) => !value || (value && value.size <= 2000000)
    ),
  releaseStatus: Yup.string("Enter Movie release status").required(
    "Movie release status is required"
  ),
  duration: Yup.string("Enter duration").required("Duration is required"),
  cast: Yup.array().min(1, "Select at least one cast member"),
  ratings: Yup.string("Enter ratings")
    .required("Ratings is required")
    .matches(/^\d*\.?\d*$/, "Enter valid ratings value")
    .test(
      "MAX_VALUE",
      "Enter value 10 or less",
      (number) => number <= 10 && number >= 0
    ),
  likes: Yup.string("Enter no of likes")
    .required("Number of likes is required")
    .matches(/[0-9]/, "Enter valid value for likes"),
});

export const movieEditSchema = Yup.object().shape({
  movieName: Yup.string("Enter movie name")
    .required("Movie name is required")
    .matches(nameRegex, "Enter valid cinema name"),
  description: Yup.string("Enter movie description").required(
    "Movie description is required"
  ),
  language: Yup.string("Enter language").required("Language is required"),
  category: Yup.string("Enter genre").required("Genre is required"),
  movieUrl: Yup.string("Enter youtube Url").required("Youtube Url is required"),
  releaseDate: Yup.string("Enter movie release date").required(
    "Movie release date is required"
  ),
  censorRating: Yup.string("Enter movie censor rating").required(
    "Movie censor rating is required"
  ),
  releaseStatus: Yup.string("Enter Movie release status").required(
    "Movie release status is required"
  ),
  duration: Yup.string("Enter duration").required("Duration is required"),
  cast: Yup.array().min(1, "Select at least one cast member"),
  ratings: Yup.string("Enter ratings")
    .required("Ratings is required")
    .matches(/^\d*\.?\d*$/, "Enter valid ratings value")
    .test(
      "MAX_VALUE",
      "Enter value 10 or less",
      (number) => number <= 10 && number >= 0
    ),
  likes: Yup.string("Enter no of likes")
    .required("Number of likes is required")
    .matches(/[0-9]/, "Enter valid value for likes"),
});

export const actorManagementSchema = Yup.object().shape({
  name: Yup.string("Enter actor name")
    .required("Actor's name is required")
    .matches(nameRegex, "Enter valid name"),
  about: Yup.string("Enter description about actor").required(
    "Description about actor is required"
  ),
  profile: Yup.mixed()
    .required("Poster is required")
    .test(
      "FILE_SIZE",
      "Uploaded file is too big.",
      (value) => !value || (value && value.size <= 2000000)
    ),
  category: Yup.string("Enter category").required("Category is required"),
});
export const actorManagementEditSchema = Yup.object().shape({
  name: Yup.string("Enter actor name")
    .required("Actor's name is required")
    .matches(nameRegex, "Enter valid name"),
  about: Yup.string("Enter description about actor").required(
    "Description about actor is required"
  ),
  profile: Yup.mixed().test(
    "FILE_SIZE",
    "Uploaded file is too big.",
    (value) => !value || (value && value.size <= 2000000)
  ),
  category: Yup.string("Enter category").required("Category is required"),
});

export const ProfileSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(2, " Full name is too short")
    .max(16, " Full name is too long")
    .required(" Full name is required")
    .test(
      "Full name",
      " Full name allows character with space between",
      charSpace
    ),
  email: Yup.string()
    .email("Enter the valid email")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Enter the valid email"
    )
    .required("Email is required"),
  mobileNumber: Yup.string()
    .required(" Mobile number is required")
    .min(10, "Min 10 digit only")
    .max(10, "Max 10 digit "),
});

export const sliderSchema = Yup.object().shape({
  title: Yup.string("Enter slider name")
    .required("Slider name is required")
    .matches(nameRegex, "Enter valid slider name"),
  poster: Yup.mixed()
    .required("Please select slider image")
    .test(
      "FILE_SIZE",
      "Uploaded file is too big.",
      (value) => !value || (value && value.size <= 2000000)
    ),
});
export const sliderEditSchema = Yup.object().shape({
  title: Yup.string("Enter slider name")
    .required("Slider name is required")
    .matches(nameRegex, "Enter valid slider name"),
});
export const generalSettingSchema = Yup.object().shape({
  facebook: Yup.string("Enter facebook link")
    .required("Facebook link is required")
    .matches(urlRegex, "Enter correct url!"),
  twitter: Yup.string("Enter twitter link")
    .required("Twitter link is required")
    .matches(urlRegex, "Enter correct url!"),
  instagram: Yup.string("Enter instagram link")
    .required("Instagram link is required")
    .matches(urlRegex, "Enter correct url!"),
  youtube: Yup.string("Enter youtube link")
    .required("Youtube link is required")
    .matches(urlRegex, "Enter correct url!"),
  contact1: Yup.string("Enter contact number")
    .required("Contact number is required")
    .matches(phoneRegExp, "Enter a valid contact number"),
  contact2: Yup.string("Enter contact number")
    .required("Contact number is required")
    .matches(phoneRegExp, "Enter a valid contact number"),
  email: Yup.string("Enter your email")
    .required("Email is required")
    .email("Email is not valid")
    .matches(emailRegex, "Enter a valid email"),
  address: Yup.string("Enter address").required("Address is required"),
  exp: Yup.string("Enter years of experience").required(
    "Years of experience is required"
  ),
  noOfScreens: Yup.string("Enter no of screens").required(
    "No of theater screens is required"
  ),
});

export const gallerySchema = Yup.object().shape({
  title: Yup.string("Enter title")
    .required("Title is required")
    .matches(nameRegex, "Enter valid title"),
  description: Yup.string("Enter description")
    .required("Description is required")
    .matches(nameRegex, "Enter valid description")
    .max(100, "Max 100 words"),
  image: Yup.mixed()
    .required("Please select image")
    .test(
      "FILE_SIZE",
      "Uploaded file is too big.",
      (value) => !value || (value && value.size <= 2000000)
    ),
});
export const galleryEditSchema = Yup.object().shape({
  title: Yup.string("Enter title")
    .required("Title is required")
    .matches(nameRegex, "Enter valid title"),
});
export const galleryImageSchema = Yup.object().shape({
  image: Yup.mixed()
    .required("Please select image")
    .test(
      "FILE_SIZE",
      "Uploaded file is too big.",
      (value) => !value || (value && value.size <= 2000000)
    ),
});
export const fAndBSchema = Yup.object().shape({
  poster: Yup.mixed()
    .required("Please select image")
    .test(
      "FILE_SIZE",
      "Uploaded file is too big.",
      (value) => !value || (value && value.size <= 2000000)
    ),
});
export const FAQSchema = Yup.object().shape({
  question: Yup.string().required("Please add question"),
  answer: Yup.string().required("Please add answer"),
});
