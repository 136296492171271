export const Api = {
  ADMIN_LOGIN: "admin/login",
  VERIFY_OTP: "admin/verify-otp",
  FORGOT_PASSWORD: "admin/forgot-password",
  RESET_PASSWORD: "admin/reset-password",
  CHANGE_PASSWORD: "admin/profile-change-password",
  GET_ROLEPERMISSION: "admin/roles",
  ADD_ROLEPERMISSION: "admin/add-role",
  EDIT_ROLEPERMISSION: "admin/edit-role",
  DELETE_ROLEPERMISSION: "admin/remove-role",
  GET_ACTORS: "admin/persons",
  ADD_EDIT_ACTORS: "admin/add-edit-person",
  DELETE_ACTORS: "admin/remove-person",
  GET_REGION: "admin/get-region",
  ADD_EDIT_REGION: "admin/add-edit-region",
  DELETE_REGION: "admin/remove-region",
  ACTIVE_DEACTIVE_REGION: "admin/active-in-active-region",
  PROFILE_INFO: "admin/get-by-id",
  PROFILE_UPDATE: "admin/edit-profile",
  REGION_ACTIVE_DEACTIVE: "admin/active-in-active-region",
  ADD_MOVIES: "admin/add-edit-movies",
  EDIT_MOVIES: "admin/edit-movies",
  DELETE_MOVIE: "admin/remove-movies",
  GET_MOVIES: "admin/movies",
  GET_CINEMA: "admin/get-cinema",
  ADD_EDIT_CINEMA: "admin/add-edit-cinema",
  DELETE_CINEMA: "admin/remove-cinema",
  GET_SLIDER: "admin/get-movie-slider",
  ADD_EDIT_SLIDER: "admin/add-edit-movie-slider",
  ACTIVE_DEACTIVE_SLIDER: "admin/update-slider-status",
  DELETE_SLIDER: "admin/delete-movie-slider",
  GET_CMS: "get-cms",
  ADD_EDIT_CMS: "admin/add-edit-cms",
  GET_SUBADMIN: "admin/subadmins",
  ADD_EDIT_SUBADMIN: "admin/add-edit-subadmin",
  DELETE_SUBADMIN: "admin/remove-subadmin",
  GET_FRANCHISE_APPLICATION_LIST: "admin/franchise-lead-details",
  GET_CONTACT_US_LIST: "admin/contact-us-details",
  GET_GENERAL_SETTINGS: "admin/site-setting",
  ADD_GENERAL_SETTINGS: "admin/add-edit-genralseting",
  GET_CINEMA_GALLERY: "admin/cinema-gallary",
  ADD_EDIT_CINEMA_GALLERY: "admin/add-edit-cinema-gallary",
  ADD_EDIT_CINEMA_GALLERY_IMAGES: "admin/add-images-cinema-gallary",
  UPDATE_CINEMA_GALLERY_STATUS: "admin/update-status-gallery",
  DELETE_CLASS: "admin/remove-gallery",
  GET_FOODS_AND_BAVERAGES: "admin/item-details-by-cinema",
  ACTIVE_DEACTIVE_FOOD_ITEM: "admin/update-item-status",
  EDIT_FOOD_ITEM: "admin/edit-food-item",
  GET_BOOKINGS_DETAILS: "admin/all-bookings",
  GET_BANNER: "admin/get-banner",
  ACTIVE_DEACTIVE_BANNER: "admin/update-banner-status",
  ADD_EDIT_BANNER: "admin/add-edit-banner",
  DELETE_BANNER: "admin/delete-banner",
  GET_USER_LIST: "admin/user-list",
  GET_SUBSCRIBER_LIST: "admin/subscribe-list",
  GET_20_MIN_FRANCHISE_APPLICATION_LIST:
    "admin/twenty-min-franchise-lead-details",
  GET_FAQ_LIST: "admin/faqs",
  ADD_EDIT_FAQ: "admin/add-edit-faqs",
  DELETE_FAQ: "admin/remove-faq",
  GET_USER_COUNT: "admin/usercount",
  GET_ALL_TOP_DATA: "admin/total-all-topdata",
  GET_REVENUE_DATA: "admin/total-revenue",
  ALL_DATA_SYNC: "admin/data-sync",
  GET_RATE_REVIEW_LIST: "admin/list-rate-and-review",
  ACTIVE_DEACTIVE_USER_REVIEW: "admin/active-de-active-rate-review",
};
