import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const SubAdminManegement = () => {
  let initialValues = {
    name: "",
    email: "",
    mobileNumber: "",
    password: "",
    confirmPassword: "",
    roleId: "",
  };
  const formikRef = useRef();
  const { adminLoginData } = PagesIndex.useSelector(
    (state) => state.admin.AdminSlice
  );
  const [subAdminList, setSubAdminList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [addOrEdit, setAddOrEdit] = useState("Add");
  const [id, setId] = useState("");

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);

  // State for searching and set data
  const [addOpen, setAddOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  useEffect(() => {
    getRolePermissionList();
    getSubAdminList();
  }, []);
  const handleOpen = (mode) => {
    setAddOrEdit(mode);
    setAddOpen(true);
    formikRef.current?.resetForm();
  };

  const handleClose = () => {
    setAddOpen(false);
    setId("");
  };
  const handleDeleteOpen = (id) => {
    setId(id);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setId("");
  };
  const getSubAdminList = () => {
    PagesIndex.DataService.get(PagesIndex.Api.GET_SUBADMIN)
      .then((res) => {
        setSubAdminList(res?.data?.data);
        setFilteredData(res?.data?.data);
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };
  const getRolePermissionList = () => {
    PagesIndex.DataService.get(PagesIndex.Api.GET_ROLEPERMISSION)
      .then((res) => {
        setRoleList(res.data.data);
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };
  const handleSubAdminSubmit = (values) => {
    const urlEncoded = new URLSearchParams();
    if (id) {
      urlEncoded.append("id", id);
    }
    urlEncoded.append("name", values?.name);
    urlEncoded.append("email", values?.email);
    urlEncoded.append("mobileNumber", values?.mobileNumber);
    urlEncoded.append("password", values?.password);
    urlEncoded.append("roleId", values?.roleId);
    // urlEncoded.append("permissions", permissions);
    PagesIndex.DataService.post(PagesIndex.Api.ADD_EDIT_SUBADMIN, urlEncoded)
      .then((res) => {
        PagesIndex.toast.success(res.data.message);
        handleClose();
        getSubAdminList();
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };

  const handleSubAdminRemove = () => {
    PagesIndex.DataService.post(PagesIndex.Api.DELETE_SUBADMIN, { id: id })
      .then((res) => {
        PagesIndex.toast.success(res.data.message);
        handleDeleteClose();
        getSubAdminList();
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };
  // Search on table
  const requestSearch = (searched) => {
    let filteredData = subAdminList.filter((data) =>
      data?.name?.toLowerCase().includes(searched?.toLowerCase())
    );
    setFilteredData(filteredData);
  };
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage - 1);
  };

  return (
    <PagesIndex.Formik
      enableReinitialize
      onSubmit={handleSubAdminSubmit}
      initialValues={initialValues}
      validationSchema={
        addOrEdit === "Add"
          ? PagesIndex.subAdminAddScema
          : PagesIndex.subAdminEditScema
      }
      innerRef={formikRef}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleBlur,
        handleSubmit,
        setFieldTouched,
      }) => (
        <>
          <Index.Box className="">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      Sub Admin Management
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="d-flex align-items-center res-set-search">
                    <Search className="search ">
                      <StyledInputBase
                        placeholder="Search"
                        inputProps={{ "aria-label": "search" }}
                        onChange={(e) => requestSearch(e.target.value)}
                      />
                    </Search>
                    {adminLoginData?.roleId?.permissions?.includes(
                      "sub_admin_add"
                    ) && (
                      <Index.Box className="common-button blue-button res-blue-button">
                        <Index.Button
                          variant="contained"
                          disableRipple
                          className="no-text-decoration"
                          onClick={() => handleOpen("Add")}
                        >
                          Add User
                        </Index.Button>
                      </Index.Box>
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  <Index.Table
                    aria-label="simple table"
                    className="table-design-main one-line-table"
                  >
                    <Index.TableHead>
                      <Index.TableRow>
                        <Index.TableCell>User Name</Index.TableCell>
                        <Index.TableCell align="center">Email</Index.TableCell>
                        {(adminLoginData?.roleId?.permissions?.includes(
                          "sub_admin_edit"
                        ) ||
                          adminLoginData?.roleId?.permissions?.includes(
                            "sub_admin_delete"
                          )) && (
                          <Index.TableCell align="right">
                            Action
                          </Index.TableCell>
                        )}
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody>
                      {filteredData?.length ? (
                        filteredData?.map((item, index) => (
                          <Index.TableRow
                            // className="inquiry-list"
                            key={item?._id}
                          >
                            <Index.TableCell>
                              {item?.name ? item?.name : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="center">
                              {item?.email ? item?.email : "-"}
                            </Index.TableCell>
                            {(adminLoginData?.roleId?.permissions?.includes(
                              "sub_admin_edit"
                            ) ||
                              adminLoginData?.roleId?.permissions?.includes(
                                "sub_admin_delete"
                              )) && (
                              <Index.TableCell align="right">
                                {adminLoginData?.roleId?.permissions?.includes(
                                  "sub_admin_edit"
                                ) && (
                                  <Index.IconButton
                                    onClick={(e) => {
                                      setId(item?._id);
                                      handleOpen("Edit");
                                      for (let key in item) {
                                        setFieldValue(key, item[key]);
                                      }
                                    }}
                                  >
                                    <Index.EditIcon />
                                  </Index.IconButton>
                                )}
                                {adminLoginData?.roleId?.permissions?.includes(
                                  "sub_admin_delete"
                                ) && (
                                  <Index.IconButton
                                    onClick={() => handleDeleteOpen(item?._id)}
                                  >
                                    <Index.DeleteIcon />
                                  </Index.IconButton>
                                )}
                              </Index.TableCell>
                            )}
                          </Index.TableRow>
                        ))
                      ) : (
                        <Index.TableRow>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="no-data-in-list"
                            colSpan={15}
                            align="center"
                          >
                            No data available
                          </Index.TableCell>
                        </Index.TableRow>
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="pagination-design flex-end">
                <Index.Stack spacing={2}>
                  <Index.Box className="pagination-count">
                    <Index.Pagination
                      count={Math.ceil(filteredData.length / 10)}
                      page={currentPage + 1}
                      onChange={handleChangePage}
                    />
                  </Index.Box>
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Modal
            open={addOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal"
          >
            <Index.Box
              sx={style}
              className="modal-inner-main add-subAdmin-modal modal-inner"
            >
              <Index.Box className="modal-header">
                <Index.Typography
                  id="modal-modal-title"
                  className="modal-title"
                  variant="h6"
                  component="h2"
                >
                  {addOrEdit} User
                </Index.Typography>
                <img
                  src={PagesIndex.Svg.cancel}
                  className="modal-close-icon"
                  onClick={handleClose}
                />
              </Index.Box>
              <Index.Box className="modal-body">
                <Index.Stack
                  component="form"
                  spacing={2}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      User Name
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        name="name"
                        placeholder="Add user name"
                        value={values?.name}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        error={errors.name && touched.name ? true : false}
                        helperText={
                          errors.name && touched.name ? errors.name : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Email
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        name="email"
                        className="form-control"
                        placeholder="Add email address"
                        value={values?.email}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        error={errors.email && touched.email ? true : false}
                        helperText={
                          errors.email && touched.email ? errors.email : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Mobile Number
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        name="mobileNumber"
                        className="form-control"
                        placeholder="Add mobile number"
                        value={values?.mobileNumber}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        error={
                          errors.mobileNumber && touched.mobileNumber
                            ? true
                            : false
                        }
                        helperText={
                          errors.mobileNumber && touched.mobileNumber
                            ? errors.mobileNumber
                            : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  {addOrEdit !== "Edit" && (
                    <>
                      <Index.Box className="input-box modal-input-box">
                        <Index.FormHelperText className="form-lable">
                          Password
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            name="password"
                            className="form-control"
                            placeholder="Add password"
                            value={values?.password}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            error={
                              errors.password && touched.password ? true : false
                            }
                            helperText={
                              errors.password && touched.password
                                ? errors.password
                                : null
                            }
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box modal-input-box">
                        <Index.FormHelperText className="form-lable">
                          Confirm Password
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            name="confirmPassword"
                            placeholder="Add confirm password"
                            value={values?.confirmPassword}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            error={
                              errors.confirmPassword && touched.confirmPassword
                                ? true
                                : false
                            }
                            helperText={
                              errors.confirmPassword && touched.confirmPassword
                                ? errors.confirmPassword
                                : null
                            }
                          />
                        </Index.Box>
                      </Index.Box>
                    </>
                  )}
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Role
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.Select
                        fullWidth
                        id="fullWidth"
                        name="roleId"
                        className="form-control"
                        renderValue={
                          values?.roleId
                            ? undefined
                            : () => "Select role"
                        }
                        value={values?.roleId}
                        onChange={handleChange}
                        error={errors.roleId && touched.roleId ? true : false}
                      >
                        {roleList.map((data) => {
                          return (
                            <Index.MenuItem value={data?._id}>
                              {data?.role}
                            </Index.MenuItem>
                          );
                        })}
                      </Index.Select>
                      <Index.FormHelperText error>
                        {errors.roleId && touched.roleId ? errors.roleId : null}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="modal-user-btn-flex">
                    <Index.Box className="discard-btn-main btn-main-primary">
                      <Index.Box className="common-button blue-button res-blue-button">
                        <Index.Button
                          variant="contained"
                          disableRipple
                          className="no-text-decoration"
                          onClick={handleClose}
                        >
                          Discard
                        </Index.Button>
                        <Index.Button
                          variant="contained"
                          disableRipple
                          className="no-text-decoration"
                          type="submit"
                          // onClick={handleClose}
                        >
                          <img
                            src={PagesIndex.Svg.save}
                            className="user-save-icon"
                          ></img>
                          Save
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Modal>
          <PagesIndex.DeleteModal
            deleteOpen={deleteOpen}
            handleDeleteClose={handleDeleteClose}
            handleDeleteRecord={handleSubAdminRemove}
          />
        </>
      )}
    </PagesIndex.Formik>
  );
};

export default SubAdminManegement;
