import { createSlice } from "@reduxjs/toolkit";
import { adminEditProfile, adminLogin } from "./AdminServices";

const initialState = {
  token: "",
  adminLoginData: [],
  isAdminLoggedIn: false,
  loading: false,
  adminProfile: {},
};

export const AdminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    adminLogout: (state) => {
      localStorage.removeItem("token");
      state.isAdminLoggedIn = false;
      state.adminLoginData = [];
      state.token = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(adminLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(adminLogin.fulfilled, (state, action) => {
        state.adminLoginData = action?.payload?.data;
        state.token = action?.payload?.data?.token;
        state.isAdminLoggedIn = true;
        state.loading = false;
      })
      .addCase(adminLogin.rejected, (state) => {
        state.loading = false;
      })

      .addCase(adminEditProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(adminEditProfile.fulfilled, (state, action) => {
        state.adminLoginData = {
          ...state.adminLoginData,
          email: action?.payload?.data?.email,
          name: action?.payload?.data?.name,
          mobileNumber: action?.payload?.data?.mobileNumber,
          image: action?.payload?.data?.image,
        };
        state.loading = false;
      })
      .addCase(adminEditProfile.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { adminLogout } = AdminSlice.actions;

export default AdminSlice.reducer;
