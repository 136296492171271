import { useState } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";
import { useEffect } from "react";
import "./CmsManagement.css";
const CmsManagement = () => {
  const params = PagesIndex.useParams();
  const { id } = params;
  const [cmsText, setCmsText] = useState("<p>Enter Text</p>");
  useEffect(() => {
    getCMSData();
  }, [id]);
  const getCMSData = () => {
    PagesIndex.DataService.get(PagesIndex.Api.GET_CMS)
      .then((res) => {
        if (id == 0) {
          setCmsText(res?.data?.data?.privacyPolicy);
        }
        if (id == 3) {
          setCmsText(res?.data?.data?.aboutUs);
        }
        if (id == 2) {
          setCmsText(res?.data?.data?.refundPolicy);
        }
        if (id == 1) {
          setCmsText(res?.data?.data?.termsCondition);
        }
        if (id == 4) {
          setCmsText(res?.data?.data?.legal_notice);
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err?.response?.data?.message);
      });
  };
  const handleSliderSubmit = () => {
    const payLoad = {
      cmsType: id,
      description: cmsText,
    };
    PagesIndex.DataService.post(PagesIndex.Api.ADD_EDIT_CMS, payLoad)
      .then((res) => {
        PagesIndex.toast.success(res.data.message);
        getCMSData();
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };
  return (
    <>
      <Index.Box className="barge-common-box cms-box">
        <Index.Box className="title-header">
          <Index.Box className="res-title-header-flex">
            <Index.Box className="title-main">
              <Index.Typography
                variant="p"
                component="p"
                className="page-title"
              >
                {id == 0
                  ? "Privacy Policy"
                  : id == 1
                  ? "Term & Conditions"
                  : id == 2
                  ? "Refund Policy"
                  : id == 3
                  ? "About Us"
                  : id == 4
                  ? "Legal Notice"
                  : ""}
              </Index.Typography>
            </Index.Box>
            <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "blockQuote",
                  // "link",
                  "|",
                  "numberedList",
                  "bulletedList",
                  // "imageUpload",
                  // "mediaEmbed",
                  "|",
                  "undo",
                  "redo",
                ],
              }}
              data={cmsText}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setCmsText(data);
              }}
              onBlur={(event, editor) => {}}
              onFocus={(event, editor) => {}}
            />
            <Index.Box className="common-button blue-button res-blue-button cms-save-btn">
              <Index.Button
                type="submit"
                variant="contained"
                disableRipple
                className="no-text-decoration"
                onClick={handleSliderSubmit}
              >
                <img src={PagesIndex.Svg.save} className="user-save-icon"></img>
                Save
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default CmsManagement;
