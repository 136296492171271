import React, { useEffect, useState } from "react";
import Index from "../../../../Index";
import PagesIndex from "../../../../PagesIndex";

const Dashboard = () => {
  const dashboardItem = [
    {
      title: "Total Revenues",
      value: "₹5,00,193.94",
      icon: PagesIndex.Svg.revenues,
    },
    {
      title: "Total Users",
      value: "1236",
      url: "/admin/user-list",
      icon: PagesIndex.Svg.users,
    },
    {
      title: "New Accounts",
      value: "1",
      icon: PagesIndex.Svg.accounts,
    },
    {
      title: "Top Cinemas",
      value: "Connplex_UAT",
      icon: PagesIndex.Svg.cinemas,
    },
    {
      title: "Top Locations",
      value: "Ahmedabad",
      icon: PagesIndex.Svg.location,
    },
    {
      title: "Top Movies",
      value: "GADAR 2 (HINDI)",
      icon: PagesIndex.Svg.movies,
    },
  ];
  const [userCountData, setUserCountData] = useState({
    userCount: 0,
    newUserCount: 0,
  });
  const [allTopData, setAllTopData] = useState({
    topMovie: {
      name: "-",
    },
    topCinema: {
      displayName: "-",
    },
  });
  const [revenueData, setRevenueData] = useState({
    totalRevenue: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getUserCount();
    getAllTopData();
    getRevenueData();
  }, []);
  const allDataSync = () => {
    setIsLoading(true);
    PagesIndex.DataService.post(PagesIndex.Api.ALL_DATA_SYNC)
      .then((res) => {
        PagesIndex.toast.status(res?.data?.message);
        setIsLoading(false);
      })
      .catch((err) => {
        PagesIndex.toast.error(err?.response?.data?.message);
        setIsLoading(false);
      });
  };
  const getUserCount = () => {
    PagesIndex.DataService.get(PagesIndex.Api.GET_USER_COUNT)
      .then((res) => {
        setUserCountData(res?.data?.data);
      })
      .catch((err) => {
        PagesIndex.toast.error(err?.response?.data?.message);
      });
  };
  const getAllTopData = () => {
    PagesIndex.DataService.get(PagesIndex.Api.GET_ALL_TOP_DATA)
      .then((res) => {
        setAllTopData(res?.data?.data);
      })
      .catch((err) => {
        PagesIndex.toast.error(err?.response?.data?.message);
      });
  };
  const getRevenueData = () => {
    PagesIndex.DataService.get(PagesIndex.Api.GET_REVENUE_DATA)
      .then((res) => {
        setRevenueData(res?.data?.data);
      })
      .catch((err) => {
        PagesIndex.toast.error(err?.response?.data?.message);
      });
  };
  return (
    <Index.Box className="barge-common-box">
      <Index.Box className="title-header">
        <Index.Box className="title-header-flex res-title-header-flex">
          <Index.Box className="title-main">
            <Index.Typography variant="p" component="p" className="page-title">
              Dashboard
            </Index.Typography>
          </Index.Box>
          <Index.Box className="common-button blue-button res-blue-button">
            <Index.Button
              variant="contained"
              disableRipple
              className="no-text-decoration"
              onClick={allDataSync}
              disabled={isLoading}
            >
              Sync
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="dashboard-body">
        <Index.Box className="dashboard-box">
          <Index.Box className="dashboard-box-left">
            <Index.Typography className="dashboard-box-title">
              Total Revenues
            </Index.Typography>
            <Index.Typography className="dashboard-box-value">
              {revenueData?.totalRevenue?.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
              })}
            </Index.Typography>
          </Index.Box>
          <Index.Box className="dashboard-box-right">
            <img
              src={PagesIndex.Svg.revenues}
              class="dash-icons"
              alt={"Total Users"}
            />
          </Index.Box>
        </Index.Box>
        <Index.Box className="dashboard-box">
          <Index.Box className="dashboard-box-left">
            <Index.Typography className="dashboard-box-title">
              Total Users
            </Index.Typography>
            <Index.Typography className="dashboard-box-value">
              {
                userCountData?.userCount
                  ?.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })
                  .split("₹")[1]
                  .split(".")[0]
              }
            </Index.Typography>
          </Index.Box>
          <Index.Box className="dashboard-box-right">
            <img
              src={PagesIndex.Svg.users}
              class="dash-icons"
              alt={"Total Users"}
            />
          </Index.Box>
        </Index.Box>
        <Index.Box className="dashboard-box">
          <Index.Box className="dashboard-box-left">
            <Index.Typography className="dashboard-box-title">
              New Accounts
            </Index.Typography>
            <Index.Typography className="dashboard-box-value">
              {
                userCountData?.newUserCount
                  ?.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })
                  .split("₹")[1]
                  .split(".")[0]
              }
            </Index.Typography>
          </Index.Box>
          <Index.Box className="dashboard-box-right">
            <img
              src={PagesIndex.Svg.accounts}
              class="dash-icons"
              alt={"Total Users"}
            />
          </Index.Box>
        </Index.Box>
        <Index.Box className="dashboard-box">
          <Index.Box className="dashboard-box-left">
            <Index.Typography className="dashboard-box-title">
              Top Cinemas
            </Index.Typography>
            <Index.Typography className="dashboard-box-value">
              {allTopData?.topCinema?.displayName}
            </Index.Typography>
          </Index.Box>
          <Index.Box className="dashboard-box-right">
            <img
              src={PagesIndex.Svg.cinemas}
              class="dash-icons"
              alt={"Total Users"}
            />
          </Index.Box>
        </Index.Box>
        <Index.Box className="dashboard-box">
          <Index.Box className="dashboard-box-left">
            <Index.Typography className="dashboard-box-title">
              Top Locations
            </Index.Typography>
            <Index.Typography className="dashboard-box-value">
              {allTopData?.topCinema?.regionId?.region}
            </Index.Typography>
          </Index.Box>
          <Index.Box className="dashboard-box-right">
            <img
              src={PagesIndex.Svg.location}
              class="dash-icons"
              alt={"Total Users"}
            />
          </Index.Box>
        </Index.Box>
        <Index.Box className="dashboard-box">
          <Index.Box className="dashboard-box-left">
            <Index.Typography className="dashboard-box-title">
              Top Movies
            </Index.Typography>
            <Index.Typography className="dashboard-box-value">
              {allTopData?.topMovie?.name}
            </Index.Typography>
          </Index.Box>
          <Index.Box className="dashboard-box-right">
            <img
              src={PagesIndex.Svg.movies}
              class="dash-icons"
              alt={"Total Users"}
            />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default Dashboard;
