import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./RegionManagement.css";
import CustomToggleButton from "../../../../common/button/CustomToggleButton";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const RegionManagement = () => {
  const { adminLoginData } = PagesIndex.useSelector(
    (state) => state.admin.AdminSlice
  );
  const [filteredData, setFilteredData] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [addOrEdit, setAddOrEdit] = useState("Add");
  const [editData, setEditData] = useState("Add");
  const [id, setId] = useState("");

  // State for pagination
  const [currentPage, setCurrentPage] = useState(0);

  // State for searching and set data
  const [addOpen, setAddOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  useEffect(() => {
    getRegionList();
  }, []);

  let initialValues;

  if (addOrEdit == "Edit") {
    initialValues = {
      regionName: editData?.region,
      image: "",
    };
  } else {
    initialValues = {
      regionName: "",
      image: "",
    };
  }

  const handleOpen = (mode) => {
    setAddOrEdit(mode);
    setAddOpen(true);
  };

  const handleClose = () => {
    setId("");
    setImageUrl("");
    setAddOpen(false);
  };
  const handleDeleteOpen = (id) => {
    setId(id);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setId("");
  };
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage - 1);
  };
  const handleStatus = (event, id) => {
    const data = {
      id: id,
      isActive: event.target.checked,
    };
    PagesIndex.DataService.post(PagesIndex.Api.ACTIVE_DEACTIVE_REGION, data)
      .then((res) => {
        if (res?.data?.status === 200) {
          PagesIndex.toast.success(res.data.message);
          getRegionList();
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };
  // Search on table
  const requestSearch = (searched) => {
    let filteredData = regionList.filter((data) =>
      data?.region?.toLowerCase().includes(searched?.toLowerCase())
    );
    setFilteredData(filteredData);
  };

  const getRegionList = () => {
    PagesIndex.DataService.get(PagesIndex.Api.GET_REGION)
      .then((res) => {
        setRegionList(res?.data?.data);
        setFilteredData(res?.data?.data);
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };

  const handleRegionSubmit = (values) => {
    const urlEncoded = new FormData();
    if (id) {
      urlEncoded.append("id", id);
    }
    urlEncoded.append("region", values?.regionName);
    urlEncoded.append("poster", values?.image);
    // urlEncoded.append("permissions", permissions);
    PagesIndex.DataService.post(PagesIndex.Api.ADD_EDIT_REGION, urlEncoded)
      .then((res) => {
        PagesIndex.toast.success(res.data.message);
        handleClose();
        getRegionList();
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };

  const handleRegionRemove = () => {
    PagesIndex.DataService.post(PagesIndex.Api.DELETE_REGION, { id: id })
      .then((res) => {
        PagesIndex.toast.success(res.data.message);
        handleDeleteClose();
        getRegionList();
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };
  return (
    <PagesIndex.Formik
      enableReinitialize
      onSubmit={handleRegionSubmit}
      initialValues={initialValues}
      validationSchema={
        addOrEdit === "Add"
          ? PagesIndex.regionSchema
          : PagesIndex.regionEditSchema
      }
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <>
          <Index.Box className="">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      Region Management
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="d-flex align-items-center res-set-search">
                    <Search className="search ">
                      <StyledInputBase
                        placeholder="Search"
                        inputProps={{ "aria-label": "search" }}
                        onChange={(e) => requestSearch(e.target.value)}
                      />
                    </Search>
                    {adminLoginData?.roleId?.permissions?.includes(
                      "region_add"
                    ) && (
                        <Index.Box className="common-button blue-button res-blue-button">
                          <Index.Button
                            variant="contained"
                            disableRipple
                            className="no-text-decoration"
                            onClick={() => handleOpen("Add")}
                          >
                            Add Region
                          </Index.Button>
                        </Index.Box>
                      )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  <Index.Table
                    aria-label="simple table"
                    className="table-design-main one-line-table region-manage-table"
                  >
                    <Index.TableHead>
                      <Index.TableRow>
                        <Index.TableCell>Place</Index.TableCell>
                        <Index.TableCell>Region</Index.TableCell>
                        <Index.TableCell align="left">Status</Index.TableCell>
                        {(adminLoginData?.roleId?.permissions?.includes(
                          "region_edit"
                        ) ||
                          adminLoginData?.roleId?.permissions?.includes(
                            "region_delete"
                          )) && (
                            <Index.TableCell align="right">Action</Index.TableCell>
                          )}
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody>
                      {filteredData?.length ? (
                        filteredData
                          ?.slice(currentPage * 10, currentPage * 10 + 10)
                          ?.map((item, index) => (
                            <Index.TableRow
                              // className="inquiry-list"
                              key={item?._id}
                            >
                              <Index.TableCell>
                                <Index.Box className="region_img">
                                  <img
                                    src={
                                      item?.image
                                        ? `${PagesIndex.IMAGES_API_ENDPOINT}/${item?.image}`
                                        : PagesIndex.Png.NoImageAvailable
                                    }
                                    onClick={handleClose}
                                    alt=""
                                  />
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell>
                                {item?.region ? item?.region : "-"}
                              </Index.TableCell>
                              <Index.TableCell align="center">
                                <CustomToggleButton
                                  defaultChecked={item?.isActive}
                                  onChange={(e) => handleStatus(e, item?._id)}
                                />
                              </Index.TableCell>
                              {(adminLoginData?.roleId?.permissions?.includes(
                                "region_edit"
                              ) ||
                                adminLoginData?.roleId?.permissions?.includes(
                                  "region_delete"
                                )) && (
                                  <Index.TableCell>
                                    {adminLoginData?.roleId?.permissions?.includes(
                                      "region_edit"
                                    ) && (
                                        <Index.IconButton
                                          onClick={(e) => {
                                            setId(item?._id);
                                            handleOpen("Edit");
                                            if (item?.image) {
                                              setImageUrl(
                                                `${PagesIndex?.IMAGES_API_ENDPOINT}/${item?.image}`
                                              );
                                            }
                                            setEditData(item);
                                          }}
                                        >
                                          <Index.EditIcon />
                                        </Index.IconButton>
                                      )}
                                    {adminLoginData?.roleId?.permissions?.includes(
                                      "region_delete"
                                    ) && (
                                        <Index.IconButton
                                          onClick={() =>
                                            handleDeleteOpen(item?._id)
                                          }
                                        >
                                          <Index.DeleteIcon />
                                        </Index.IconButton>
                                      )}
                                  </Index.TableCell>
                                )}
                            </Index.TableRow>
                          ))
                      ) : (
                        <Index.TableRow>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="no-data-in-list"
                            colSpan={15}
                            align="center"
                          >
                            No data available
                          </Index.TableCell>
                        </Index.TableRow>
                      )}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="pagination-design flex-end">
                <Index.Stack spacing={2}>
                  <Index.Box className="pagination-count">
                    <Index.Pagination
                      count={Math.ceil(filteredData.length / 10)}
                      page={currentPage + 1}
                      onChange={handleChangePage}
                    />
                  </Index.Box>
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Modal
            open={addOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal"
          >
            <Index.Box
              sx={style}
              className="modal-inner-main add-region-modal modal-inner"
            >
              <Index.Box className="modal-header">
                <Index.Typography
                  id="modal-modal-title"
                  className="modal-title"
                  variant="h6"
                  component="h2"
                >
                  {addOrEdit} Region
                </Index.Typography>
                <img
                  src={PagesIndex.Svg.cancel}
                  className="modal-close-icon"
                  onClick={handleClose}
                />
              </Index.Box>

              <Index.Box className="modal-body">
                <Index.Stack
                  component="form"
                  spacing={2}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Region
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        name="regionName"
                        className="form-control"
                        placeholder="Add region name"
                        value={values?.regionName}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        error={
                          errors.regionName && touched.regionName ? true : false
                        }
                        helperText={
                          errors.regionName && touched.regionName
                            ? errors.regionName
                            : null
                        }
                      />
                    </Index.Box>
                    <Index.FormHelperText className="form-lable">
                      Image (size 50x50 px)
                    </Index.FormHelperText>
                    {/* {imageUrl && (
                      <img src={imageUrl} className="region-image" />
                    )}
                    <Index.Box
                      className={`form-group region_img_upload ${errors.image && touched.image ? "error" : ""
                        }`}
                    >
                      <Index.Box className="common-button grey-button change-profile">
                        <Index.InputLabel>
                          <input
                            type="file"
                            className="change-profile-input"
                            accept="image/*"
                            name="image"
                            onChange={(e) => {
                              try {
                                setFieldValue(
                                  "image",
                                  e.currentTarget.files[0]
                                );
                                setImageUrl(
                                  URL.createObjectURL(e.currentTarget.files[0])
                                );
                              } catch (error) {
                                console.error(error);
                                e.currentTarget.value = null;
                              }
                            }}
                            error={errors.image && touched.image ? true : false}
                            helperText={
                              errors.image && touched.image
                                ? errors.image
                                : false
                            }
                          />
                        </Index.InputLabel>
                      </Index.Box>
                    </Index.Box> */}
                    <Index.Box className="file-upload-btn-main">
                      <Index.Button
                        variant="contained"
                        component="label"
                        className="file-upload-btn"
                      >
                        {imageUrl ?
                          <img src={imageUrl} className="upload-profile-img" />
                          :
                          <img
                            className="
                            upload-img"
                            src={
                              PagesIndex.Svg.add
                            }
                          />
                        }
                        <input
                          hidden
                          accept="image/*"
                          multiple
                          name="image"
                          type="file"
                          onChange={(e) => {
                            try {
                              setFieldValue(
                                "image",
                                e.currentTarget.files[0]
                              );
                              setImageUrl(
                                URL.createObjectURL(e.currentTarget.files[0])
                              );
                            } catch (error) {
                              console.error(error);
                              e.currentTarget.value = null;
                            }
                          }}
                          error={errors.image && touched.image ? true : false}
                          helperText={
                            errors.image && touched.image
                              ? errors.image
                              : false
                          }
                        />
                      </Index.Button>
                    </Index.Box>

                    <Index.FormHelperText error>
                      {errors.image && touched.image ? errors.image : false}
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="modal-user-btn-flex">
                    <Index.Box className="discard-btn-main btn-main-primary">
                      <Index.Box className="common-button blue-button res-blue-button">
                        <Index.Button
                          variant="contained"
                          disableRipple
                          className="no-text-decoration"
                          onClick={handleClose}
                        >
                          Discard
                        </Index.Button>
                        <Index.Button
                          type="submit"
                          variant="contained"
                          disableRipple
                          className="no-text-decoration"
                        >
                          <img
                            src={PagesIndex.Svg.save}
                            className="user-save-icon"
                          ></img>

                          {addOrEdit == "Add" ? "Save" : "Update"}
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Modal>
          <PagesIndex.DeleteModal
            deleteOpen={deleteOpen}
            handleDeleteClose={handleDeleteClose}
            handleDeleteRecord={handleRegionRemove}
          />
        </>
      )}
    </PagesIndex.Formik>
  );
};

export default RegionManagement;
