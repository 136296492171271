import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./UserList.css";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const UserList = () => {
  const { adminLoginData } = PagesIndex.useSelector(
    (state) => state.admin.AdminSlice
  );
  const [filteredData, setFilteredData] = useState([]);
  const [userList, setUserList] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  // State for pagination
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    getUserList();
  }, []);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage - 1);
  };

  // Search on table
  const requestSearch = (searched) => {
    setCurrentPage(0);
    let filteredData = userList.filter(
      (data) =>
        `${data?.firstName?.toLowerCase()} ${data?.lastName?.toLowerCase()}`?.includes(
          searched?.toLowerCase()
        ) ||
        data?.email?.toLowerCase()?.includes(searched?.toLowerCase()) ||
        data?.mobileNumber?.toString()?.includes(searched?.toString())
    );
    setFilteredData(filteredData);
  };

  const getUserList = () => {
    PagesIndex.DataService.get(PagesIndex.Api.GET_USER_LIST)
      .then((res) => {
        setUserList(res?.data?.data);
        setFilteredData(res?.data?.data);
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };

  const exportData = (data, fileName, type) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
  const generateCSV = async () => {
    const headers = ["User", "Email", "Mobile Number", "Date"];
    let modifiedData = [];
    filteredData.map((item) => {
      let objData = [
        item?.firstName ? `${item?.firstName} ${item?.lastName}` : "-",
        item?.email ? item?.email : "-",
        item?.mobileNumber ? item?.mobileNumber : "-",
        item?.createdAt
          ? PagesIndex.moment(item?.createdAt).format("DD/MM/YYYY hh:mm A")
          : "-",
      ];
      modifiedData.push(objData.join(","));
    });
    exportData(
      [[headers, ...modifiedData].join("\n")],
      `${PagesIndex.moment().format("DD-MM-YYYY hh:mm:ss A")}_User_list.csv`,
      "text/csv"
    );
  };
  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  User list
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => requestSearch(e.target.value)}
                  />
                </Search>
                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.Button
                    variant="contained"
                    disableRipple
                    className="no-text-decoration"
                    onClick={() => {
                      generateCSV();
                    }}
                  >
                    Export CSV
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main one-line-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell>User</Index.TableCell>
                    <Index.TableCell>Email</Index.TableCell>
                    <Index.TableCell>Mobile Number</Index.TableCell>
                    <Index.TableCell>Date</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody>
                  {filteredData?.length ? (
                    filteredData
                      ?.slice(currentPage * 10, currentPage * 10 + 10)
                      ?.map((item, index) => (
                        <Index.TableRow
                          // className="inquiry-list"
                          key={item?._id}
                        >
                          <Index.TableCell>
                            {item?.firstName
                              ? `${item?.firstName} ${item?.lastName}`
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {item?.email ? item?.email : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {item?.mobileNumber ? item?.mobileNumber : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {item?.createdAt
                              ? PagesIndex.moment(item?.createdAt).format(
                                  "DD/MM/YYYY hh:mm A"
                                )
                              : "-"}
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                  ) : (
                    <Index.TableRow>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="no-data-in-list"
                        colSpan={15}
                        align="center"
                      >
                        No data available
                      </Index.TableCell>
                    </Index.TableRow>
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Pagination
                  count={Math.ceil(filteredData.length / 10)}
                  page={currentPage + 1}
                  onChange={handleChangePage}
                />
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default UserList;
