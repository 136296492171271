import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./MovieManagement.css";
import { ContactlessOutlined } from "@mui/icons-material";
import moment from "moment";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const MovieManagement = () => {
  const { adminLoginData } = PagesIndex.useSelector(
    (state) => state.admin.AdminSlice
  );
  const [filteredData, setFilteredData] = useState([]);
  const [movieList, setMovieList] = useState([]);
  const [actorList, setActorList] = useState([]);
  const [addOrEdit, setAddOrEdit] = useState("Add");
  const [editData, setEditData] = useState("Add");
  const [id, setId] = useState("");
  const [hasFilmCode, setHasFilmCode] = useState("");

  // State for pagination
  const [currentPage, setCurrentPage] = useState(0);

  // State for searching and set data
  const [addOpen, setAddOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  let initialValues;

  if (addOrEdit == "Edit") {
    initialValues = {
      movieName: editData.name,
      description: editData.description,
      censorRating: editData.censorRating,
      poster: "",
      releaseDate: PagesIndex.dayjs(editData.filmOpeningDate),
      releaseStatus: editData.status,
      category: editData.category,
      duration: editData.duration,
      language: editData.languages,
      cast: editData.starCast,
      movieUrl: editData.youtubeVideoUrl,
      ratings: editData?.rating?.toString(),
      likes: editData?.likes?.toString(),
    };
  } else {
    initialValues = {
      movieName: "",
      description: "",
      censorRating: "",
      poster: "",
      releaseDate: "",
      releaseStatus: "",
      category: "",
      duration: "",
      cast: [],
      language: "",
      movieUrl: "",
      ratings: "",
      likes: "",
    };
  }

  useEffect(() => {
    getActorsList();
    getMoviesList();
  }, []);

  const handleOpen = (mode) => {
    setAddOrEdit(mode);
    setAddOpen(true);
  };

  const handleClose = () => {
    setHasFilmCode("");
    setId("");
    setImageUrl("");
    setAddOpen(false);
  };
  const handleDeleteOpen = (id) => {
    setId(id);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setId("");
  };
  // Search on table
  const requestSearch = (searched) => {
    let filteredData = movieList.filter((data) =>
      data?.name?.toLowerCase().includes(searched?.toLowerCase())
    );
    setFilteredData(filteredData);
  };

  const getActorsList = () => {
    PagesIndex.DataService.get(PagesIndex.Api.GET_ACTORS)
      .then((res) => {
        setActorList(res.data.data);
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.mes);
      });
  };

  const getMoviesList = () => {
    PagesIndex.DataService.get(PagesIndex.Api.GET_MOVIES)
      .then((res) => {
        if (res?.data?.status === 200) {
          setMovieList(res?.data?.data);
          setFilteredData(res?.data?.data);
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.mes);
      });
  };

  const handleMoviesSubmit = (values) => {
    const urlEncoded = new FormData();
    if (id) {
      urlEncoded.append("id", id);
    }
    if (hasFilmCode) {
      urlEncoded.append("filmCode", hasFilmCode);
    }
    urlEncoded.append("name", values?.movieName);
    urlEncoded.append("description", values?.description);
    urlEncoded.append("poster", values?.poster);
    urlEncoded.append(
      "filmOpeningDate",
      PagesIndex.dayjs(values?.releaseDate).format("YYYY-MM-DD")
    );
    urlEncoded.append("censorRating", values?.censorRating);
    urlEncoded.append("status", values?.releaseStatus);
    urlEncoded.append("category", values?.category);
    urlEncoded.append("duration", values?.duration);
    urlEncoded.append("languages", values?.language);
    urlEncoded.append("youtubeVideoUrl", values.movieUrl);
    urlEncoded.append("starCast", JSON.stringify(values.cast));
    urlEncoded.append("rating", values.ratings);
    urlEncoded.append("likes", values.likes);

    PagesIndex.DataService.post(
      hasFilmCode ? PagesIndex.Api.EDIT_MOVIES : PagesIndex.Api.ADD_MOVIES,
      urlEncoded
    )
      .then((res) => {
        PagesIndex.toast.success(res.data.message);
        handleClose();
        getMoviesList();
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage - 1);
  };
  const handleMovieRemove = () => {
    PagesIndex.DataService.post(PagesIndex.Api.DELETE_MOVIE, { id: id })
      .then((res) => {
        PagesIndex.toast.success(res.data.message);
        handleDeleteClose();
        getMoviesList();
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };

  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Movie Management
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => requestSearch(e.target.value)}
                  />
                </Search>
                {adminLoginData?.roleId?.permissions?.includes("movie_add") && (
                  <Index.Box className="common-button blue-button res-blue-button">
                    <Index.Button
                      variant="contained"
                      disableRipple
                      className="no-text-decoration"
                      onClick={() => handleOpen("Add")}
                    >
                      Add Movie
                    </Index.Button>
                  </Index.Box>
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main one-line-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell width="15%">Image</Index.TableCell>
                    <Index.TableCell width="25%">Movie</Index.TableCell>
                    <Index.TableCell width="25%">Release Date</Index.TableCell>
                    <Index.TableCell width="25%">
                      Release Status
                    </Index.TableCell>
                    {(adminLoginData?.roleId?.permissions?.includes(
                      "movie_edit"
                    ) ||
                      adminLoginData?.roleId?.permissions?.includes(
                        "movie_delete"
                      )) && (
                      <Index.TableCell align="right" width="10%">
                        Action
                      </Index.TableCell>
                    )}
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody>
                  {filteredData?.length ? (
                    filteredData
                      ?.slice(currentPage * 10, currentPage * 10 + 10)
                      ?.map((item, index) => (
                        <Index.TableRow
                          // className="inquiry-list"
                          key={item?._id}
                        >
                          <Index.TableCell>
                            <Index.Box className="class_img">
                              <img
                                src={
                                  item?.poster
                                    ? `${PagesIndex.IMAGES_API_ENDPOINT}/${item?.poster}`
                                    : PagesIndex.Png.NoImageAvailable
                                }
                                onClick={handleClose}
                                alt=""
                              />
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell>
                            {item?.name ? item?.name : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {item?.filmOpeningDate
                              ? PagesIndex.dayjs(item?.filmOpeningDate).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {item?.status
                              ? item?.status === 1
                                ? "Now Playing"
                                : "Upcoming"
                              : "-"}
                          </Index.TableCell>
                          {(adminLoginData?.roleId?.permissions?.includes(
                            "movie_edit"
                          ) ||
                            adminLoginData?.roleId?.permissions?.includes(
                              "movie_delete"
                            )) && (
                            <Index.TableCell align="right">
                              {adminLoginData?.roleId?.permissions?.includes(
                                "movie_edit"
                              ) && (
                                <Index.IconButton
                                  onClick={(e) => {
                                    if (item?.filmCode) {
                                      setHasFilmCode(item?.filmCode);
                                    }
                                    setId(item?._id);
                                    if (item?.poster) {
                                      setImageUrl(
                                        `${PagesIndex?.IMAGES_API_ENDPOINT}/${item?.poster}`
                                      );
                                    }
                                    handleOpen("Edit");
                                    setEditData(item);
                                  }}
                                >
                                  <Index.EditIcon />
                                </Index.IconButton>
                              )}
                              {adminLoginData?.roleId?.permissions?.includes(
                                "movie_edit"
                              ) && (
                                <Index.IconButton
                                  onClick={() => handleDeleteOpen(item?._id)}
                                >
                                  <Index.DeleteIcon />
                                </Index.IconButton>
                              )}
                            </Index.TableCell>
                          )}
                        </Index.TableRow>
                      ))
                  ) : (
                    <Index.TableRow>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="no-data-in-list"
                        colSpan={15}
                        align="center"
                      >
                        No data available
                      </Index.TableCell>
                    </Index.TableRow>
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Pagination
                  count={Math.ceil(filteredData.length / 10)}
                  page={currentPage + 1}
                  onChange={handleChangePage}
                />
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* Add movie Modal start */}
      <Index.Modal
        open={addOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box
          sx={style}
          className="modal-inner-main add-cinema-modal modal-inner"
        >
          <Index.Box className="modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="modal-title"
              variant="h6"
              component="h2"
            >
              {addOrEdit} Movie
            </Index.Typography>
            <img
              src={PagesIndex.Svg.cancel}
              className="modal-close-icon"
              onClick={handleClose}
            />
          </Index.Box>
          <Index.Box className="modal-body">
            <PagesIndex.Formik
              enableReinitialize
              onSubmit={handleMoviesSubmit}
              initialValues={initialValues}
              validationSchema={
                addOrEdit === "Add"
                  ? PagesIndex.movieSchema
                  : PagesIndex.movieEditSchema
              }
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <Index.Stack
                  component="form"
                  spacing={2}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Movie
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        name="movieName"
                        className="form-control"
                        placeholder="Add movie name"
                        value={values?.movieName}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        error={
                          errors.movieName && touched.movieName ? true : false
                        }
                        helperText={
                          errors.movieName && touched.movieName
                            ? errors.movieName
                            : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Description
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        name="description"
                        className="form-control"
                        placeholder="Add movie description"
                        value={values?.description}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        error={
                          errors.description && touched.description
                            ? true
                            : false
                        }
                        helperText={
                          errors.description && touched.description
                            ? errors.description
                            : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Movie Poster (size 244x366 px)
                    </Index.FormHelperText>
                    {/* {imageUrl && (
                      <img src={imageUrl} className="region-image" />
                    )}
                    <Index.Box className="form-group region_img_upload">
                      <Index.Box className="common-button grey-button change-profile">
                        <input
                          name="poster"
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={(event) => {
                            setFieldValue("poster", event.currentTarget.files[0]);
                            setImageUrl(
                              URL.createObjectURL(event.currentTarget.files[0])
                            );
                          }}
                        />
                        <Index.FormHelperText error>
                          {errors.poster && touched.poster ? errors.poster : null}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box> */}
                    <Index.Box className="file-upload-btn-main">
                      <Index.Button
                        variant="contained"
                        component="label"
                        className="file-upload-btn"
                      >
                        {imageUrl ? (
                          <img src={imageUrl} className="upload-profile-img" />
                        ) : (
                          <img
                            className="
                            upload-img"
                            src={PagesIndex.Svg.add}
                          />
                        )}
                        <input
                          hidden
                          accept="image/*"
                          multiple
                          name="poster"
                          type="file"
                          onChange={(e) => {
                            try {
                              setFieldValue("poster", e.currentTarget.files[0]);
                              setImageUrl(
                                URL.createObjectURL(e.currentTarget.files[0])
                              );
                            } catch (error) {
                              console.error(error);
                              e.currentTarget.value = null;
                            }
                          }}
                          error={errors.image && touched.image ? true : false}
                          helperText={
                            errors.image && touched.image ? errors.image : false
                          }
                        />
                      </Index.Button>
                      <Index.FormHelperText error>
                        {errors.poster && touched.poster ? errors.poster : null}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Release Date
                    </Index.FormHelperText>
                    <Index.Box className="form-group date-picker">
                      <Index.LocalizationProvider
                        dateAdapter={Index.AdapterDayjs}
                      >
                        <Index.DatePicker
                          fullWidth
                          id="fullWidth"
                          name="releaseDate"
                          // value={values.releaseDate}
                          className="form-control"
                          format="DD/MM/YYYY"
                          placeholder="Add release date"
                          value={values?.releaseDate || null}
                          slotProps={{
                            textField: {
                              helperText:
                                errors.releaseDate && touched.releaseDate
                                  ? errors.releaseDate
                                  : null,
                              error:
                                errors.releaseDate && touched.releaseDate
                                  ? true
                                  : false,
                            },
                          }}
                          onChange={(e) => {
                            setFieldValue("releaseDate", e, true);
                          }}
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              sx={{ width: "100%" }}
                            />
                          )}
                        />
                      </Index.LocalizationProvider>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Release Status
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.Select
                        fullWidth
                        id="fullWidth"
                        name="releaseStatus"
                        className="form-control"
                        value={values?.releaseStatus}
                        displayEmpty
                        renderValue={
                          values?.releaseStatus
                            ? undefined
                            : () => "Select release status"
                        }
                        onChange={handleChange}
                        error={
                          errors.releaseStatus && touched.releaseStatus
                            ? true
                            : false
                        }
                      >
                        <Index.MenuItem value={1}>Now Playing</Index.MenuItem>
                        <Index.MenuItem value={2}>Upcoming</Index.MenuItem>
                        <Index.MenuItem value={3}>Closed</Index.MenuItem>
                      </Index.Select>
                      <Index.FormHelperText error>
                        {errors.releaseStatus && touched.releaseStatus
                          ? errors.releaseStatus
                          : null}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Censor Rating
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        name="censorRating"
                        className="form-control"
                        placeholder="Add movie censor rating"
                        value={values?.censorRating}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        error={
                          errors.censorRating && touched.censorRating
                            ? true
                            : false
                        }
                        helperText={
                          errors.censorRating && touched.censorRating
                            ? errors.censorRating
                            : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Duration
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        name="duration"
                        className="form-control"
                        placeholder="Add duration"
                        value={values?.duration}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        error={
                          errors.duration && touched.duration ? true : false
                        }
                        helperText={
                          errors.duration && touched.duration
                            ? errors.duration
                            : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Youtube Url
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        name="movieUrl"
                        className="form-control"
                        placeholder="Add movieUrl"
                        value={values?.movieUrl}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        error={
                          errors.movieUrl && touched.movieUrl ? true : false
                        }
                        helperText={
                          errors.movieUrl && touched.movieUrl
                            ? errors.movieUrl
                            : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Genre
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        name="category"
                        className="form-control"
                        placeholder="Add genre"
                        value={values?.category}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        error={
                          errors.category && touched.category ? true : false
                        }
                        helperText={
                          errors.category && touched.category
                            ? errors.category
                            : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Language
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        name="language"
                        className="form-control"
                        placeholder="Add language"
                        value={values?.language}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        error={
                          errors.language && touched.language ? true : false
                        }
                        helperText={
                          errors.language && touched.language
                            ? errors.language
                            : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.Box className="input-box modal-input-box">
                      <Index.FormHelperText className="form-lable">
                        Cast
                      </Index.FormHelperText>
                      <Index.Box className="form-group cinema-auto-input">
                        <Index.Autocomplete
                          className="cinema-auto-input"
                          multiple
                          options={actorList}
                          getOptionLabel={(option) => option?.name}
                          defaultValue={() => {
                            let idArray = [];
                            values?.cast.map((data1) => {
                              let item = actorList.find(
                                (data2) => data1?.starCastId?._id === data2?._id
                              );
                              idArray.push(item);
                            });
                            return idArray;
                          }}
                          disableCloseOnSelect
                          renderOption={(props, option, { selected }) => {
                            return (
                              <Index.MenuItem
                                key={option._id}
                                value={option?.name}
                                sx={{ justifyContent: "space-between" }}
                                {...props}
                              >
                                <Index.ListItemText>
                                  {option?.name}
                                </Index.ListItemText>
                                {selected ? <Index.Check /> : null}
                              </Index.MenuItem>
                            );
                          }}
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              error={errors.cast && touched.cast ? true : false}
                              helperText={
                                errors.cast && touched.cast ? errors.cast : null
                              }
                              placeholder="Enter actor name"
                              className="movie-management-input movie-management-category"
                            />
                          )}
                          onChange={(e, val) => {
                            let idArray = [];
                            val.map((item) => {
                              let objData = {
                                starCastId: item._id,
                              };
                              idArray.push(objData);
                            });
                            setFieldValue("cast", idArray);
                          }}
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box modal-input-box">
                      <Index.FormHelperText className="form-lable">
                        Ratings
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          name="ratings"
                          className="form-control"
                          placeholder="Add ratings"
                          value={values?.ratings}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          error={
                            errors.ratings && touched.ratings ? true : false
                          }
                          helperText={
                            errors.ratings && touched.ratings
                              ? errors.ratings
                              : null
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box modal-input-box">
                      <Index.FormHelperText className="form-lable">
                        No. Of Likes
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          name="likes"
                          className="form-control"
                          placeholder="Add no. of likes"
                          value={values?.likes}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          error={errors.likes && touched.likes ? true : false}
                          helperText={
                            errors.likes && touched.likes ? errors.likes : null
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="modal-user-btn-flex">
                    <Index.Box className="discard-btn-main btn-main-primary">
                      <Index.Box className="common-button blue-button res-blue-button">
                        <Index.Button
                          variant="contained"
                          disableRipple
                          className="no-text-decoration"
                          onClick={handleClose}
                        >
                          Discard
                        </Index.Button>
                        <Index.Button
                          type="submit"
                          variant="contained"
                          disableRipple
                          className="no-text-decoration"
                        >
                          <img
                            src={PagesIndex.Svg.save}
                            className="user-save-icon"
                          ></img>
                          Save
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Stack>
              )}
            </PagesIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      <PagesIndex.DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteClose={handleDeleteClose}
        handleDeleteRecord={handleMovieRemove}
      />
    </>
  );
};

export default MovieManagement;
