import React from "react";
import Index from "../../../container/Index";
import PagesIndex from "../../../container/PagesIndex";

const Sidebar = ({ open, setOpen }) => {
  const location = PagesIndex.useLocation();
  const { adminLoginData } = PagesIndex.useSelector(
    (state) => state.admin.AdminSlice
  );
  const [openCms, setOpenCms] = React.useState(false);
  const handleClickAccount = () => {
    setOpenCms(!openCms);
  };
  const sidebarMenus = [
    {
      title: "Dashboard",
      pathName: "/admin/dashboard",
      icon: <Index.DashboardIcon />,
      show: true,
    },
    // {
    //   title: "Manage User",
    //   pathName: "/admin/manageuser",
    //   icon: PagesIndex?.Svg?.sidebar2,
    // },
    {
      title: "Users",
      pathName: "/admin/user-list",
      icon: <Index.PeopleIcon />,
      show: adminLoginData?.roleId?.permissions?.includes("user_view"),
    },
    {
      title: "Role Permission",
      pathName: "/admin/role-permission",
      icon: <Index.LockPersonIcon />,
      show: adminLoginData?.roleId?.permissions?.includes("role_view"),
    },
    {
      title: "Region",
      pathName: "/admin/region",
      icon: <Index.PlaceIcon />,
      show: adminLoginData?.roleId?.permissions?.includes("region_view"),
    },
    {
      title: "Slider",
      pathName: "/admin/slider",
      icon: <Index.ViewCarouselIcon />,
      show: adminLoginData?.roleId?.permissions?.includes("slider_view"),
    },
    {
      title: "Banner",
      pathName: "/admin/banner",
      icon: <Index.SwipeIcon />,
      show: adminLoginData?.roleId?.permissions?.includes("slider_view"),
    },
    {
      title: "Cinema",
      pathName: "/admin/cinema",
      icon: <Index.LocationCityIcon />,
      show: adminLoginData?.roleId?.permissions?.includes("cinema_view"),
    },
    {
      title: "Movie",
      pathName: "/admin/movie",
      icon: <Index.MovieIcon />,
      show: adminLoginData?.roleId?.permissions?.includes("movie_view"),
    },
    {
      title: "Movie Reviews",
      pathName: "/admin/userReviews",
      icon: <Index.MovieIcon />,
      show: adminLoginData?.roleId?.permissions?.includes("movie_review_view"),
    },
    {
      title: "Actors",
      pathName: "/admin/actors",
      icon: <Index.RecentActorsIcon />,
      show: adminLoginData?.roleId?.permissions?.includes("actor_view"),
    },
    {
      title: "Bookings",
      pathName: "/admin/bookings",
      icon: <Index.LocalActivityIcon />,
      show: adminLoginData?.roleId?.permissions?.includes("bookings_view"),
    },
    {
      title: "Transaction History",
      pathName: "/admin/transaction-history",
      icon: <Index.ReceiptLongIcon />,
      show: adminLoginData?.roleId?.permissions?.includes("transaction_view"),
    },
    {
      title: "Franchise",
      pathName: "/admin/franchise",
      icon: <Index.DomainAddIcon />,
      show: adminLoginData?.roleId?.permissions?.includes(
        "applyForFranchise_view"
      ),
    },
    {
      title: "20 Min Franchise",
      pathName: "/admin/20-min-franchise",
      icon: <Index.DomainAddIcon />,
      show: adminLoginData?.roleId?.permissions?.includes(
        "applyForFranchise_view"
      ),
    },
    {
      title: "Gallery",
      pathName: "/admin/gallery",
      icon: <Index.CollectionsIcon />,
      show: adminLoginData?.roleId?.permissions?.includes("gallery_view"),
    },
    {
      title: "Foods & Beverages",
      pathName: "/admin/foods-and-beverages",
      icon: <Index.FastfoodIcon />,
      show: adminLoginData?.roleId?.permissions?.includes(
        "food_beverages_view"
      ),
    },
    {
      title: "Contact us",
      pathName: "/admin/contactUs",
      icon: <Index.ContactPageIcon />,
      show: adminLoginData?.roleId?.permissions?.includes("contactUs_view"),
    },
    {
      title: "20 Days Trial",
      pathName: "/admin/trial_subs",
      icon: <Index.SubscriptionsIcon />,
      show: adminLoginData?.roleId?.permissions?.includes("trial_subs_view"),
    },
    {
      title: "FAQ",
      pathName: "/admin/faq",
      icon: <Index.LiveHelpIcon />,
      show: adminLoginData?.roleId?.permissions?.includes("faq_view"),
    },
    {
      title: "Sub Admin",
      pathName: "/admin/sub-admin",
      icon: <Index.ManageAccountsIcon />,
      show: adminLoginData?.roleId?.permissions?.includes("sub_admin_view"),
    },
  ];

  return (
    <>
      <Index.Box
        className={`sidebar-main ${!open ? "active" : "sidebar-none"}`}
      >
        <Index.Box className="sidebar-logo">
          <img src={PagesIndex?.Png?.connplexlogo} alt="logo123" />
        </Index.Box>

        <Index.Box className="sidebar-links">
          <Index.Box className="sidebar-ul">
            {sidebarMenus.map((item, index) => {
              return (
                item?.show && (
                  <PagesIndex.Link
                    to={item.pathName}
                    onClick={(e) => setOpen(false)}
                    className="sidebar-links"
                    key={index}
                  >
                    <Index.Box
                      className={`sidebar-li ${
                        location.pathname.includes(item.pathName)
                          ? "active"
                          : ""
                      }`}
                      key={index}
                    >
                      <Index.Box>
                        {/* <img src={item.icon} alt="dashboard-icon" />{" "} */}
                        {item?.icon}
                        {item.title}
                      </Index.Box>
                    </Index.Box>
                  </PagesIndex.Link>
                )
              );
            })}
            <PagesIndex.Link className="sidebar-links">
              <Index.Box className={`sidebar-li`} onClick={handleClickAccount}>
                <Index.Box>
                  <Index.DvrIcon />
                  CMS
                </Index.Box>
                {openCms ? (
                  <Index.ExpandLess className="expandless-icon" />
                ) : (
                  <Index.ExpandMore className="expandmore-icon" />
                )}
              </Index.Box>
              <Index.Box className="submenu-main">
                <Index.Collapse
                  in={openCms}
                  timeout="auto"
                  unmountOnExit
                  className="submenu-collapse"
                >
                  <Index.List
                    component="div"
                    disablePadding
                    className="admin-sidebar-submenulist"
                  >
                    <Index.ListItem
                      className={`sidebar-li ${
                        location.pathname === `/admin/cms/1` ? "active" : ""
                      }`}
                    >
                      <PagesIndex.Link
                        to={"/admin/cms/1"}
                        className="sidebar-links"
                      >
                        <Index.Box className={`sidebar-li-cms`}>
                          <Index.Box>
                            <Index.GavelIcon />
                            Terms & conditions
                          </Index.Box>
                        </Index.Box>
                      </PagesIndex.Link>
                    </Index.ListItem>
                    <Index.ListItem
                      className={`sidebar-li ${
                        location.pathname === `/admin/cms/3` ? "active" : ""
                      }`}
                    >
                      <PagesIndex.Link
                        to={"/admin/cms/3"}
                        className="sidebar-links"
                      >
                        <Index.Box className={`sidebar-li-cms`}>
                          <Index.Box>
                            <Index.InfoIcon />
                            About Us
                          </Index.Box>
                        </Index.Box>
                      </PagesIndex.Link>
                    </Index.ListItem>
                    <Index.ListItem
                      className={`sidebar-li ${
                        location.pathname === `/admin/cms/0` ? "active" : ""
                      }`}
                    >
                      <PagesIndex.Link
                        to={"/admin/cms/0"}
                        className="sidebar-links"
                      >
                        <Index.Box className={`sidebar-li-cms`}>
                          <Index.Box>
                            <Index.PolicyIcon />
                            Privacy Policy
                          </Index.Box>
                        </Index.Box>
                      </PagesIndex.Link>
                    </Index.ListItem>
                    <Index.ListItem
                      className={`sidebar-li ${
                        location.pathname === `/admin/cms/2` ? "active" : ""
                      }`}
                    >
                      <PagesIndex.Link
                        to={"/admin/cms/2"}
                        className="sidebar-links"
                      >
                        <Index.Box className={`sidebar-li-cms`}>
                          <Index.Box>
                            <Index.CurrencyExchangeIcon />
                            Refund Policy
                          </Index.Box>
                        </Index.Box>
                      </PagesIndex.Link>
                    </Index.ListItem>
                    <Index.ListItem
                      className={`sidebar-li ${
                        location.pathname === `/admin/cms/4` ? "active" : ""
                      }`}
                    >
                      <PagesIndex.Link
                        to={"/admin/cms/4"}
                        className="sidebar-links"
                      >
                        <Index.Box className={`sidebar-li-cms`}>
                          <Index.Box>
                            <Index.CurrencyExchangeIcon />
                            Legal Notice
                          </Index.Box>
                        </Index.Box>
                      </PagesIndex.Link>
                    </Index.ListItem>
                  </Index.List>
                </Index.Collapse>
              </Index.Box>
            </PagesIndex.Link>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Sidebar;
