import Svg from "../assests/Svg";
import Png from "../assests/Png";
import Jpg from "../assests/Jpg";
import { Formik, FieldArray } from "formik";

import {
  Link,
  useNavigate,
  Navigate,
  useLocation,
  useParams,
  createSearchParams,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  rolePermissionSchema,
  regionSchema,
  subAdminAddScema,
  cinemaSchema,
  movieSchema,
  actorManagementSchema,
  actorManagementEditSchema,
  sliderSchema,
  subAdminEditScema,
  movieEditSchema,
  cinemaEditSchema,
  regionEditSchema,
  sliderEditSchema,
  generalSettingSchema,
  gallerySchema,
  galleryEditSchema,
  galleryImageSchema,
  fAndBSchema,
  FAQSchema,
} from "../validation/FormikValidation";
import { Api } from "../config/Api";
import DataService, { IMAGES_API_ENDPOINT } from "../config/DataService";
import DeleteModal from "../common/DeleteModal";
import dayjs from "dayjs";
import moment from "moment/moment";
import CustomToggleButton from "../common/button/CustomToggleButton";
const PagesIndex = {
  fAndBSchema,
  sliderEditSchema,
  regionEditSchema,
  cinemaEditSchema,
  movieEditSchema,
  subAdminEditScema,
  useParams,
  sliderSchema,
  IMAGES_API_ENDPOINT,
  DeleteModal,
  Svg,
  Png,
  Jpg,
  dayjs,
  Formik,
  FieldArray,
  Link,
  useSelector,
  useDispatch,
  useLocation,
  useNavigate,
  Navigate,
  toast,
  ToastContainer,
  regionSchema,
  rolePermissionSchema,
  subAdminAddScema,
  cinemaSchema,
  movieSchema,
  actorManagementSchema,
  Api,
  DataService,
  moment,
  generalSettingSchema,
  gallerySchema,
  galleryEditSchema,
  createSearchParams,
  galleryImageSchema,
  FAQSchema,
  actorManagementEditSchema,
  CustomToggleButton,
};

export default PagesIndex;
