import React from "react";
import { BrowserRouter, Route, Routes as Routess } from "react-router-dom";
import { createBrowserHistory } from "history";
import Login from "../container/auth/login/Login";
import ForgotPassword from "../container/auth/forgotPassword/ForgotPassword";
import ResetPassword from "../container/auth/resetPassword/ResetPassword";
import SignUp from "../container/auth/signUp/SignUp";
import DashboardLayout from "../container/pages/admin/dashboard/dashboardLayout/DashboardLayout";
import Dashboard from "../container/pages/admin/dashboard/dashboardPage/Dashboard";
import ManageUser from "../container/pages/admin/dashboard/dashboardPage/menageUser/ManageUser";
import Otp from "../container/auth/otp/Otp";
import PublicLayout from "./public/PublicLayout";
import PrivateLayout from "./private/PrivateLayout";
import ChangePassword from "../container/pages/admin/profile/ChangePassword";
import Profile from "../container/pages/admin/profile/Profile";

// import ManageUser from "../container/pages/admin/menageUser/ManageUserBarge";
import RolePermissionManagement from "../container/pages/admin/rolePermissionMangement/RolePermissionManagement";
import RegionManagement from "../container/pages/admin/regionManagement/RegionManagement";
import SubAdminManegement from "../container/pages/admin/subAdminManagement/SubAdminManegement";
import CinemaManagement from "../container/pages/admin/cinemaManagement/CinemaManagement";
import MovieManagement from "../container/pages/admin/movieManagement/MovieManagement";
import ActorsManagement from "../container/pages/admin/actorsManagement/ActorsManagement";
// import Home from "../container/pages/admin/dashboard";
import SliderManagement from "../container/pages/admin/sliderManagement/SliderManagement";
import CmsManagement from "../container/pages/admin/cmsManagement/CmsManagement";
import ContactUsList from "../container/pages/admin/contactUsList/ContactUsList";
import FranchiseApplicationList from "../container/pages/admin/franchiseApplicationList/FranchiseApplicationList";
import GalleryManagement from "../container/pages/admin/galleryManagement/GalleryManagement";
import GalleryImageManagement from "../container/pages/admin/galleryImageManagement/GalleryImageManagement";
import FoodsAndBeveregesManagement from "../container/pages/admin/foodsAndBeveregesManagement/FoodsAndBeveregesManagement";
import BookingManagement from "../container/pages/admin/bookingManagement/BookingManagement";
import BannerManagement from "../container/pages/admin/bannerManagement/BannerManagement";
import UserList from "../container/pages/admin/userList/UserList";
import TwentyMinFranchiseApplicationList from "../container/pages/admin/twentyMinFranchiseApplicationList/TwentyMinFranchiseApplicationList";
import SubscriberList from "../container/pages/admin/subscriberList/SubscriberList";
import FaqManagement from "../container/pages/admin/faqManagement/FaqManagement";
import TransactionHistory from "../container/pages/admin/transactionHistory/TransactionHistory";
import UserMovieReviewList from "../container/pages/admin/userMovieReviewList/UserMovieReviewList";
const history = createBrowserHistory();
export default function Routes() {
  return (
    <BrowserRouter history={history}>
      <Routess>
        <Route element={<PublicLayout />}>
          <Route path="/" index element={<Login />} />
          <Route path="/signup" index element={<SignUp />} />
          <Route path="/resetpassword" index element={<ResetPassword />} />
          <Route path="/forgotpassword" index element={<ForgotPassword />} />
          <Route path="/otp" index element={<Otp />} />
        </Route>

        <Route element={<PrivateLayout />}>
          <Route path="/admin" element={<DashboardLayout />}>
            <Route path="dashboard" index element={<Dashboard />} />
            <Route path="profile" index element={<Profile />} />
            <Route path="change-password" index element={<ChangePassword />} />
            <Route path="manageuser" index element={<ManageUser />} />
            <Route
              path="role-permission"
              index
              element={<RolePermissionManagement />}
            />
            <Route path="user-list" index element={<UserList />} />
            <Route path="region" index element={<RegionManagement />} />
            <Route path="sub-admin" index element={<SubAdminManegement />} />
            <Route path="slider" index element={<SliderManagement />} />
            <Route path="banner" index element={<BannerManagement />} />
            <Route path="cinema" index element={<CinemaManagement />} />
            <Route path="movie" index element={<MovieManagement />} />
            <Route path="actors" index element={<ActorsManagement />} />
            <Route path="cms/:id" index element={<CmsManagement />} />
            <Route path="gallery" index element={<GalleryManagement />} />
            <Route
              path="gallery/:cId"
              index
              element={<GalleryImageManagement />}
            />
            <Route
              path="franchise"
              index
              element={<FranchiseApplicationList />}
            />
            <Route
              path="20-min-franchise"
              index
              element={<TwentyMinFranchiseApplicationList />}
            />
            <Route
              path="foods-and-beverages"
              index
              element={<FoodsAndBeveregesManagement />}
            />
            <Route path="bookings" index element={<BookingManagement />} />
            <Route path="contactUs" index element={<ContactUsList />} />
            <Route path="trial_subs" index element={<SubscriberList />} />
            <Route path="faq" index element={<FaqManagement />} />
            <Route path="userReviews" index element={<UserMovieReviewList />} />
            <Route
              path="transaction-history"
              index
              element={<TransactionHistory />}
            />
          </Route>
        </Route>
      </Routess>
    </BrowserRouter>
  );
}
