import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./BookingManagement.css";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const BookingManagement = () => {
  const { adminLoginData } = PagesIndex.useSelector(
    (state) => state.admin.AdminSlice
  );
  const [bookingsList, setBookingsList] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    getBookingsList(currentPage, search);
  }, [currentPage]);
  useEffect(() => {
    setCurrentPage(0);
    getBookingsList(0, search);
  }, [search]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage - 1);
  };

  const getBookingsList = (pageNo, searchValue) => {
    const formData = new URLSearchParams();
    formData.append("currentPageNumber", pageNo);
    formData.append("search", searchValue);
    PagesIndex.DataService.post(PagesIndex.Api.GET_BOOKINGS_DETAILS, formData)
      .then((res) => {
        setBookingsList(res?.data?.data);
        setTotal(res.data.count);
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };

  const handleOpen = (msg) => {
    setOpen(true);
    setData(msg);
  };
  const handleClose = () => {
    setOpen(false);
    setData("");
  };
  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Bookings
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </Search>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main one-line-table region-manage-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell width="10%">Image</Index.TableCell>
                    <Index.TableCell width="20%">Movie & Show</Index.TableCell>
                    <Index.TableCell width="20%">
                      Foods & Beverages
                    </Index.TableCell>
                    <Index.TableCell width="25%">User Details</Index.TableCell>
                    <Index.TableCell width="10%">Date</Index.TableCell>
                    <Index.TableCell width="10%">
                      Payment Status
                    </Index.TableCell>
                    <Index.TableCell width="10%">
                      Booking Status
                    </Index.TableCell>
                    <Index.TableCell width="10%">Refund Status</Index.TableCell>
                    <Index.TableCell width="5%">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody>
                  {bookingsList?.length ? (
                    bookingsList?.map((item, index) => (
                      <Index.TableRow
                        className="inquiry-list"
                        key={item?._id}
                        onClick={() => handleOpen(item)}
                      >
                        <Index.TableCell>
                          <Index.Box className="booking-movie-img">
                            <img
                              src={
                                item?.movieData?.poster
                                  ? `${PagesIndex.IMAGES_API_ENDPOINT}/${item?.movieData?.poster}`
                                  : PagesIndex.Png.NoImageAvailable
                              }
                              onClick={handleClose}
                              alt=""
                            />
                          </Index.Box>
                        </Index.TableCell>
                        <Index.TableCell>
                          <b>{item?.movieData?.name}</b> <br></br>
                          {`${PagesIndex.moment(item?.showData?.sessionRealShow)
                            .utc()
                            .format("MMM DD, YYYY hh:mm A")}`}
                          <br></br>
                          {item?.cinemaData?.cinemaName}
                          <br></br>
                          {item?.showData?.screenName} -{" "}
                          {item?.commitBookingData?.strSeatInfo}
                          <br></br>
                          <b>Total seat :</b>{" "}
                          {
                            item?.commitBookingData?.strSeatInfo
                              ?.split("-")[1]
                              .trim()
                              .split(",").length
                          }
                          <br></br>
                          <b>Total Amount :</b>{" "}
                          {(
                            item?.paymentResponse?.amount / 100
                          )?.toLocaleString("en-IN", {
                            style: "currency",
                            currency: "INR",
                          })}
                        </Index.TableCell>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="table-td"
                        >
                          <b>{item?.fAndBDetails?.length ? "Items:" : ""}</b>
                          <br></br>
                          {item?.fAndBDetails
                            ? item?.fAndBDetails.map((data) => {
                              return (
                                <>
                                  {data?.name}(x{data?.quantity}) ₹
                                  {parseFloat(data?.price).toFixed(2)}
                                  <br></br>
                                </>
                              );
                            })
                            : "-"}
                          <br></br>
                          {item?.fAndBDetails
                            ? <>
                              <b>F&B Total : </b>
                              {item?.fAndBDetails
                                ?.reduce(
                                  (currentValue, data) =>
                                    data?.price + currentValue,
                                  0
                                )
                                ?.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                })}
                            </>
                            : "-"}
                        </Index.TableCell>
                        <Index.TableCell>
                          {item?.firstName &&
                            `${item?.userData?.firstName} ${item?.userData?.lastName}`(
                              <br></br>
                            )}
                          {item?.userData?.email && item?.userData?.email}
                          {item?.userData?.email && <br></br>}
                          {item?.userData?.mobileNumber &&
                            item?.userData?.mobileNumber}
                        </Index.TableCell>
                        <Index.TableCell>
                          {item?.createdAt
                            ? PagesIndex.moment(item?.createdAt).format(
                              "DD/MM/YYYY"
                            )
                            : "-"}
                          <br />
                          {item?.createdAt
                            ? PagesIndex.moment(item?.createdAt).format(
                              "hh:mm A"
                            )
                            : "-"}
                        </Index.TableCell>
                        <Index.TableCell align="center">
                          {item?.paymentsStatus ? (
                            <span className="status-green">Success</span>
                          ) : (
                            <span className="status-red">Fail</span>
                          )}
                        </Index.TableCell>
                        <Index.TableCell align="center">
                          {item?.commitStatus ? (
                            <span className="status-green">Success</span>
                          ) : (
                            <span className="status-red">Fail</span>
                          )}
                        </Index.TableCell>
                        <Index.TableCell align="center">
                          {item?.refundStatus ? (
                            <span className="status-green">Success</span>
                          ) : (
                            <span className="status-red">Fail</span>
                          )}
                        </Index.TableCell>
                        <Index.TableCell align="right">
                          <Index.IconButton onClick={handleOpen}>
                            <Index.Visibility />
                          </Index.IconButton>
                        </Index.TableCell>
                      </Index.TableRow>
                    ))
                  ) : (
                    <Index.TableRow>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="no-data-in-list"
                        colSpan={15}
                        align="center"
                      >
                        No data available
                      </Index.TableCell>
                    </Index.TableRow>
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Pagination
                  count={Math.ceil(total / 10)}
                  page={currentPage + 1}
                  onChange={handleChangePage}
                />
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box
          sx={style}
          className="modal-inner-main add-role-modal modal-inner"
        >
          <Index.Box className="modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="modal-title"
              variant="h6"
              component="h2"
            >
              View Details
            </Index.Typography>
            <img
              src={PagesIndex.Svg.cancel}
              className="modal-close-icon"
              onClick={handleClose}
            />
          </Index.Box>
          <Index.Box className="modal-body">
            <Index.Box className="booking-detail-inner">
              <Index.Box className="booking-detail-img">
                <img
                  src={
                    data?.movieData?.poster
                      ? `${PagesIndex.IMAGES_API_ENDPOINT}/${data?.movieData?.poster}`
                      : PagesIndex.Png.NoImageAvailable
                  }
                  onClick={handleClose}
                  alt=""
                />
                <Index.Box className="booking-detail-id">
                  <Index.Typography className="booking-detail-id-value">
                    {data?.commitBookingData?.strBookId
                      ? data?.commitBookingData?.strBookId
                      : data?.commitBookingData || "-"}
                  </Index.Typography>
                  <Index.Typography className="booking-detail-id-label">
                    (Booking Id)
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="booking-detail-right">
                <Index.Box className="log-data">
                  <Index.Box className="log-title">Payment Response:</Index.Box>
                  <Index.Box className="log-text">
                    <Index.Box className="log-text-title" component="span">
                      Transaction Id :
                    </Index.Box>{" "}
                    {data?.initTransId}
                  </Index.Box>
                  <Index.Box className="log-text">
                    <Index.Box className="log-text-title" component="span">
                      Amount :
                    </Index.Box>{" "}
                    {parseFloat(data?.paymentResponse?.amount / 100).toFixed(2)}
                  </Index.Box>
                  <Index.Box className="log-text">
                    <Index.Box className="log-text-title" component="span">
                      Payment Method :
                    </Index.Box>{" "}
                    {data?.paymentResponse?.method?.charAt(0).toUpperCase() +
                      data?.paymentResponse?.method?.slice(1).toLowerCase()}
                  </Index.Box>
                  <Index.Box className="log-text">
                    <Index.Box className="log-text-title" component="span">
                      Order Id :
                    </Index.Box>{" "}
                    {data?.paymentResponse?.order_id}
                  </Index.Box>
                  <Index.Box className="log-text">
                    <Index.Box className="log-text-title" component="span">
                      Payment Id :
                    </Index.Box>{" "}
                    {data?.paymentResponse?.id}
                  </Index.Box>
                  <Index.Box className="log-text">
                    <Index.Box className="log-text-title" component="span">
                      Payment Status :
                    </Index.Box>{" "}
                    {data?.paymentResponse?.status?.charAt(0).toUpperCase() +
                      data?.paymentResponse?.status?.slice(1).toLowerCase()}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {/* <Index.Box className="modal-user-btn-flex">
              <Index.Box className="discard-btn-main btn-main-primary">
                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.Button
                    variant="contained"
                    disableRipple
                    className="no-text-decoration"
                    onClick={handleClose}
                  >
                    Close
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box> */}
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default BookingManagement;
