import logo from "./images/Svg/logo.svg";
import collapse from "./images/Svg/collapse.svg";
import bell from "./images/Svg/bell.svg";
import search from "./images/Svg/search.svg";
import sidebar1 from "./images/Svg/sidebar1.svg";
import sidebar2 from "./images/Svg/sidebar2.svg";
import sidebar3 from "./images/Svg/sidebar3.svg";
import sidebar4 from "./images/Svg/sidebar4.svg";
import sidebar5 from "./images/Svg/sidebar5.svg";
import sidebar6 from "./images/Svg/sidebar6.svg";
import sidebar7 from "./images/Svg/sidebar7.svg";
import sidebar8 from "./images/Svg/sidebar8.svg";
import plus from "./images/Svg/plus.svg";
import save from "./images/Svg/save.svg";
import close from "./images/Svg/close.svg";
import settings from "./images/Svg/settings.svg";
import cancel from "./images/Svg/cancel.svg";
import add from "./images/Svg/img-upload-icon.svg";
import accounts from "./images/Svg/accounts.svg";
import cinemas from "./images/Svg/cinemas.svg";
import location from "./images/Svg/location.svg";
import movies from "./images/Svg/movies.svg";
import revenues from "./images/Svg/revenues.svg";
import users from "./images/Svg/users.svg";

const Svg = {
  logo,
  collapse,
  search,
  bell,
  sidebar1,
  sidebar2,
  sidebar3,
  sidebar4,
  sidebar5,
  sidebar6,
  sidebar7,
  sidebar8,
  plus,
  save,
  close,
  settings,
  cancel,
  add,
  accounts,
  cinemas,
  location,
  movies,
  revenues,
  users,
};

export default Svg;
